<template>
	<div class="member_index">
		<top-search @changeMode="changeMode"></top-search>
		<div class="null-page" v-show="yes"></div>
		<div class="member-index" v-if="member">
			<div class="member_info">
				<div class="left_img">
					<div class="setting iconfont1 icon-shezhi" @click="changeMyCurrent(10)"></div>
					<img class="headimg" :src="$img(member.headimg)" @click="changeMyCurrent(10)" @error="member.headimg = defaultHeadImage" />
					<div class="member_name">{{member.realname ? member.realname : member.nickname}}</div>
					<div class="card" v-if="is_agent">
						<img class="is_agent" src="@/assets/images/newImg/isAgent.png" />
						经纪人
					</div>
					<div class="index" @click="toMyIndex()">主页</div>
				</div>
				<div class="right_info">
					<div class="top">
						<div class="item" :class="current_role=='buyer'?'active':''" @click="changeRole('buyer')">买方</div>
						<div class="item" :class="current_role=='seller'?'active':''" @click="changeRole('seller')">卖方</div>
						<div class="item" :class="current_role=='agent'?'active':''" @click="changeRole('agent')">经纪人</div>
					</div>
					<div class="bottom">
						<div class="item" @click="changeMyCurrent(1)" :class="my_current==1 ? 'active':''">
							<img v-if="my_current==1" src="@/assets/images/newImg/member_order.png" />
							<img v-else src="@/assets/images/newImg/black_member_order.png" />
							订单
						</div>
						<template v-if="current_role == 'buyer'" >
							<div class="item" @click="changeMyCurrent(12)" :class="my_current==12 ? 'active':''">
								<img v-if="my_current==12" src="@/assets/images/newImg/member_contract.png" />
								<img v-else src="@/assets/images/newImg/black_member_contract.png" />
								授权
							</div>
							<div class="item" @click="changeMyCurrent(2)" :class="my_current==2 ? 'active':''">
								<img v-if="my_current==2" src="@/assets/images/newImg/member_demand.png" />
								<img v-else src="@/assets/images/newImg/black_member_demand.png" />
								需求
							</div>
							<div class="item" @click="changeMyCurrent(3)" :class="my_current==3 ? 'active':''">
								<img v-if="my_current==3" src="@/assets/images/newImg/member_cart.png" />
								<img v-else src="@/assets/images/newImg/black_member_cart.png" />
								购物车
							</div>
							<div class="item" @click="changeMyCurrent(13)" :class="my_current==13 ? 'active':''">
								<img v-if="my_current==13" src="@/assets/images/newImg/member_cart.png" />
								<img v-else src="@/assets/images/newImg/black_member_cart.png" />
								防伪
							</div>
						</template>
						<template v-else-if="current_role == 'seller'">
							<div class="item" @click="changeMyCurrent(4)" :class="my_current==4 ? 'active':''">
								<img v-if="my_current==4" src="@/assets/images/newImg/member_resource.png" />
								<img v-else src="@/assets/images/newImg/black_member_resource.png" />
								资源
							</div>
							<div class="item" @click="changeMyCurrent(5)" :class="my_current==5 ? 'active':''">
								<img v-if="my_current==5" src="@/assets/images/newImg/member_bid.png" />
								<img v-else src="@/assets/images/newImg/black_member_bid.png" />
								投标
							</div>
							<div class="item" @click="changeMyCurrent(6)" :class="my_current==6 || my_current==11 ? 'active':''">
								<img v-if="my_current==6 || my_current==11" src="@/assets/images/newImg/member_estimated.png" />
								<img v-else src="@/assets/images/newImg/black_member_estimated.png" />
								估价
							</div>
						</template>
						<template v-else-if="current_role == 'agent'">
							<div class="item" @click="changeMyCurrent(4)" :class="my_current==4 ? 'active':''">
								<img v-if="my_current==4" src="@/assets/images/newImg/member_resource.png" />
								<img v-else src="@/assets/images/newImg/black_member_resource.png" />
								资源
							</div>
							<div class="item" @click="changeMyCurrent(12)" :class="my_current==12 ? 'active':''">
								<img v-if="my_current==12" src="@/assets/images/newImg/member_contract.png" />
								<img v-else src="@/assets/images/newImg/black_member_contract.png" />
								授权
							</div>
							<div class="item" @click="changeMyCurrent(7)" :class="my_current==7 ? 'active':''">
								<img v-if="my_current==7" src="@/assets/images/newImg/member_commission.png" />
								<img v-else src="@/assets/images/newImg/black_member_commission.png" />
								佣金
							</div>
							<div class="item" @click="changeMyCurrent(8)" :class="my_current==8 ? 'active':''">
								<img v-if="my_current==8" src="@/assets/images/newImg/member_brokerage.png" />
								<img v-else src="@/assets/images/newImg/black_member_brokerage.png" />
								经纪
							</div>
						</template>
						<div class="item" @click="changeMyCurrent(9)" :class="my_current==9 ? 'active':''">
							<img v-if="my_current == 9" src="@/assets/images/newImg/member_account.png" />
							<img v-else src="@/assets/images/newImg/black_member_account.png" />
							钱包
						</div>
					</div>
				</div>
			</div>
			
			<div class="mode_content">
				<order-list :role="current_role" v-if="my_current == 1" ></order-list>
				<demand-list v-if="my_current == 2"></demand-list>
				<cart :cartType="cart_type" v-if="my_current == 3"></cart>
				<resource-list :role="current_role" v-if="my_current == 4"></resource-list>
				<bid-list v-if="my_current == 5"></bid-list>
				<estimated-list v-if="my_current == 6" @lookEstimated="lookEstimated"></estimated-list>
				<commission-list v-if="my_current == 7"></commission-list>
				<brokerage-module v-if="my_current == 8"></brokerage-module>
				<wallet-info v-if="my_current==9" :rechargeShow="rechargeShow"></wallet-info>
				<member-info v-if="my_current==10"></member-info>
				<goods-estimate v-if="my_current == 11" :goodsId="goods_estimate_id" ></goods-estimate>
				<accredit-list :role="current_role" v-if="my_current == 12"></accredit-list>
				<security-code v-if="my_current == 13"></security-code>
			</div>
		</div>
	</div>
</template>

<script>
import { orderNum, couponNum,  levelList } from '@/api/member/index';
import { shopMemberDetail } from "@/api/resource"
import { mapGetters } from 'vuex';
import { myGoodslist } from '@/api/resource.js'
import securityCode from '@/components/security_code'
import topSearch from '@/components/top_search';
import walletInfo from '@/components/walletInfo';
import resourceList from '@/views/member/resource_list';
import demandList from '@/views/member/demand_list';
import estimatedList from '@/views/member/estimated_list';
import cart from '@/components/cart';
import memberAccount from '@/views/member/account';
import rechargeList from '@/views/member/recharge_list';
import rechargeOrder from '@/views/member/recharge_order';
import rechargeDetail from '@/views/member/recharge_detail';
import applyWithDrawal from '@/views/member/apply_withdrawal';
import accountList from '@/views/member/account_list';
import accountEdit from '@/views/member/account_edit';
import withDrawal from '@/views/member/withdrawal';
import withDrawalDetail from '@/views/member/withdrawal_detail';
import autoInfo from '@/components/authInfo';
import orderList from '@/components/order_list';
import accreditList from '@/components/accreditList';
import branchOffice from '@/components/branchOffice';
import commissionList from '@/components/commissionList';
import goodsEstimate from '@/components/goodsEstimate';
import memberInfo from '@/components/memberInfo';
import bidList from '@/components/bidList';
import brokerageModule from '@/components/brokerageModule';
export default {
	name: 'member',
	components: {
		topSearch,
		resourceList,
		demandList,
		estimatedList,
		cart,
		memberAccount,
		rechargeList,
		rechargeOrder,
		rechargeDetail,
		applyWithDrawal,
		accountList,
		accountEdit,
		withDrawal,
		withDrawalDetail,
		autoInfo,
		orderList,
		branchOffice,
		commissionList,
		goodsEstimate,
		memberInfo,
		bidList,
		brokerageModule,
		walletInfo,
		accreditList,
		securityCode
	},
	data: () => {
		return {
			current_role:'buyer',//当前角色 身份
			my_current:1,
			current_mode:1,
			growth: '',
			levelList: [],
			member_level: {},
			progress: 0,
			yes: true,
			resource_loading:true,
			single_mode:'',
			wallet_status:'member_account',
			recharge_detail_id:'',
			account_edit_id:'',
			account_list_back:'',
			with_drawal_detail_id:'',
			order_detai_id:0,
			is_agent:false,
			goods_estimate_id:'',
			cart_type:'',
			rechargeShow:false,
		};
	},
	created() {
		// this.memberInfo();
		this.getShopMemberDetail();
		if(this.$route.query.my_current) this.my_current = this.$route.query.my_current;
		if(this.$route.query.current_role) this.current_role = this.$route.query.current_role;
		if(this.$route.query.cart_type) this.cart_type = this.$route.query.cart_type;
		if(this.$route.query.isRecharge) this.rechargeShow = this.$route.query.isRecharge ? true : false;
		if(localStorage.getItem('ml_role')) this.current_role = localStorage.getItem('ml_role')
	},
	computed: {
		...mapGetters(['defaultHeadImage', 'defaultGoodsImage', 'member',"token"])
	},
	watch: {
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false;
		}, 300);
	},
	methods: {
		toMyIndex(){
			this.$router.push('/personal-'+this.member.member_id);
		},
		lookEstimated(id){
			this.single_mode = 'goods_estimate';
			this.goods_estimate_id = id;
			this.my_current = 11;
		},
		getShopMemberDetail(){
			shopMemberDetail()
			.then(res=>{
				if(res.code>=0 && res.data.shop_member_info){
					this.is_agent = true;
				}
			})
		},
		changeWalletStatus(data){
			this.my_current = 9;
			if(data[0]=='recharge_detail' && data[1]){
				this.recharge_detail_id = data[1];
			}else{
				this.recharge_detail_id = '';
			}
			if(data[0]=='account_list' && data[1]){
				this.account_list_back = data[1]
			}else{
				this.account_list_back = '';
			}
			if(data[0]=='account_edit' && data[1]){
				this.account_edit_id = data[1];
			}else{
				this.account_edit_id = '';
			}
			if(data[0]=='with_drawal_detail' && data[1]){
				this.with_drawal_detail_id = data[1];
			}else{
				this.with_drawal_detail_id = '';
			}
			this.wallet_status = data[0];
		},
		changeSingleMode(mode){
			switch (mode){
				case 'cart':
					this.single_mode = mode;
					this.my_current = 4;
					break;
				case 'collect':
					this.single_mode = mode;
					this.my_current = 6;
					break;
				case 'resource':
					this.single_mode = mode;
					this.my_current = 1;
					break;
				case 'estimated':
					this.single_mode = mode;
					this.my_current = 3;
					break;
				case 'authentication'://认证
					this.single_mode = mode;
					this.my_current = 7;
					break;
				case 'commission'://佣金
					this.single_mode = mode;
					this.my_current = 8;
					break;
				case 'order'://订单
					this.single_mode = mode;
					this.my_current = 9;
					break;
				case 'wallet'://钱包
					this.single_mode = mode;
					this.my_current = 9;
					this.wallet_status = 'member_account';
					break;
				default:
					break;
			}
		},
		changeMode(mode){
			this.current_mode = mode;
		},
		changeRole(mode){
			this.single_mode = '';
			this.current_role = mode;
			this.my_current=1;
			localStorage.setItem("ml_role",mode );
		},
		changeMyCurrent(mode){
			if(this.my_current == mode) return;
			this.my_current = mode;
		},
		
	}
};
</script>
<style lang="scss" scoped>
.member_index {
	width: 100%;
	position: relative;
	padding-top: 30px;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #ffffff;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}
.member-index{
	margin-top: 30px;
}
.goods_list{
	&.ziyuan{
		.goods_item{
			cursor: pointer;
			margin-bottom: 30px;
			display: flex;
			border-radius: 10px;
			background-color: #fff;
			align-items: center;
			.goods_img{
				border-radius: 10px;
				width: 213px;
				height: 213px;
				display: block;
				margin-right: 20px;
			}
			.goods_info{
				flex: 1;
				display: flex;
				height: 213px;
				align-items: center;
				padding: 20px 22px 20px 0;
				box-sizing: border-box;
				.left{
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					height: 100%;
					flex: 1;
					.top_info{
						display: flex;
						flex-direction: column;
						.goods_tit{
							color: #30373D;
							font-size: 19px;
							line-height: 39px;
							font-weight: 600;
							margin-bottom: 10px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}
						.goods_tag{
							display: flex;
							align-items: center;
							height: 22px;
							overflow: hidden;
							flex-wrap: wrap;
							.tag{
								color: #30373D;
								font-size: 15px;
								line-height: 15px;
								padding: 3px 9px 4px;
								background-color: rgba(48, 55, 61, 0.05);
								border-radius: 3px;
								margin-right: 10px;
								&:last-child{
									margin-right: 0;
								}
							}
						}
						.accredits{
							display: flex;
							align-items: center;
							flex-wrap: wrap;
							height: 64px;
							overflow: hidden;
							align-content: flex-start;
							.item{
								color: #30373D;
								font-size: 15px;
								line-height: 20px;
								padding: 0 4px;
								border: 1px solid rgba(48, 55, 61, 0.20);
								border-radius: 3px;
								margin-right: 10px;
								margin-top: 10px;
								box-sizing: border-box;
							}
						}
					}
					
					.other_info{
						display: flex;
						align-items: center;
						div{
							color: #999;
							font-size: 13px;
							line-height: 22px;
							margin-right: 30px;
							&:last-child{
								margin-right: 0;
							}
						}
					}
				}
				.right{
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: flex-end;
					.price{
						display: flex;
						align-items: baseline;
						color: #FF3300;
						font-size: 13px;
						line-height: 39px;
						.num{
							font-size: 19px;
							font-weight: 600;
						}
					}
					.row{
						display: flex;
						align-items: flex-end;
						&.first{
							margin-bottom: 10px;
							margin-top: 27px;
						}
						.info{
							display: flex;
							align-items: center;
							img{
								width: 18px;
								height: 18px;
								display: block;
								margin-right: 20px;
							}
							.iconfont1{
								font-size: 18px;
								line-height: 1;
								margin-right: 20px;
								color: #999;
							}
							.logo{
								border-radius: 50%;
							}
							.last{
								margin-right: 0;
							}
						}
						.time{
							color: #999;
							font-size: 13px;
							line-height: 18px;
						}
						.kefu{
							color: #fff;
							font-size: 13px;
							line-height: 18px;
							padding: 7px 40px;
							border-radius: 60px;
							background-color: #30373D;
							margin-left: 52px;
							&.wx{
								color: #1BCF42;
								background-color: rgba(27, 207, 66, 0.20);
							}
						}
					}
				}
			}
		}
		
	}
	
	
	&.demand{
		.goods_item{
			margin-bottom: 30px;
			display: flex;
			border-radius: 10px;
			background-color: #fff;
			align-items: center;
			padding: 20px 22px;
			height: 213px;
			box-sizing: border-box;
			.goods_info{
				flex: 1;
				display: flex;
				align-items: center;
				height: 100%;
				.left{
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					height: 100%;
					flex: 1;
					.top_info{
						display: flex;
						flex-direction: column;
						.goods_tit{
							color: #30373D;
							font-size: 19px;
							line-height: 39px;
							font-weight: 600;
							margin-bottom: 10px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}
						.goods_tag{
							display: flex;
							align-items: center;
							height: 22px;
							overflow: hidden;
							flex-wrap: wrap;
							.tag{
								color: #30373D;
								font-size: 15px;
								line-height: 15px;
								padding: 3px 9px 4px;
								background-color: rgba(48, 55, 61, 0.05);
								border-radius: 3px;
								margin-right: 10px;
								&:last-child{
									margin-right: 0;
								}
							}
						}
						.accredits{
							display: flex;
							align-items: center;
							flex-wrap: wrap;
							height: 64px;
							overflow: hidden;
							align-content: flex-start;
							.item{
								color: #30373D;
								font-size: 15px;
								line-height: 20px;
								padding: 0 4px;
								border: 1px solid rgba(48, 55, 61, 0.20);
								border-radius: 3px;
								margin-right: 10px;
								margin-top: 10px;
								box-sizing: border-box;
							}
						}
					}
					.other_info{
						display: flex;
						align-items: center;
						div{
							color: #999;
							font-size: 13px;
							line-height: 22px;
							margin-right: 30px;
							&:last-child{
								margin-right: 0;
							}
						}
					}
				}
				.right{
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: flex-end;
					.price{
						display: flex;
						align-items: baseline;
						color: #FF3300;
						font-size: 13px;
						line-height: 39px;
						.num{
							font-size: 19px;
							font-weight: 600;
						}
					}
					.row{
						display: flex;
						align-items: flex-end;
						&.first{
							margin-bottom: 10px;
							margin-top: 27px;
						}
						.info{
							display: flex;
							align-items: center;
							img{
								width: 18px;
								height: 18px;
								display: block;
								margin-right: 20px;
							}
							.iconfont1{
								font-size: 18px;
								line-height: 1;
								margin-right: 20px;
								color: #999;
							}
							.logo{
								border-radius: 50%;
							}
							.last{
								margin-right: 0;
							}
						}
						.time{
							color: #999;
							font-size: 13px;
							line-height: 22px;
						}
						.kefu{
							color: #fff;
							font-size: 13px;
							line-height: 18px;
							padding: 7px 40px;
							border-radius: 60px;
							background-color: #30373D;
							margin-left: 52px;
							&.wx{
								color: #1BCF42;
								background-color: rgba(27, 207, 66, 0.20);
							}
						}
					}
				}
			}
		}
	}
}

.member_info{
	display: flex;
	.left_img{
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		border-radius: 12px;
		justify-content: center;
		padding: 0 30px;
		box-sizing: border-box;
		width: 286px;
		height: 273px;
		margin-right: 20px;
		position: relative;
		box-shadow: 0px 25px 56px 0px rgba(240,237,246,0.54);
		.index{
			font-size: 13px;
			line-height: 14px;
			color:#30373D;
			font-weight:600;
			cursor: pointer;
			margin-top: 30px;
		}
		.setting{
			position: absolute;
			z-index:10;
			right:22px;
			top:23px;
			cursor: pointer;
			line-height: 21px;
			font-size: 21px;
			color:#30373D;
		}
		.headimg{
			object-fit: cover;
			width: 61px;
			height: 61px;
			display: block;
			margin-bottom: 10px;
			border-radius:50%;
			cursor: pointer;
		}
		.member_name{
			color: #30373D;
			font-size: 19px;
			line-height: 39px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			font-weight: 600;
		}
		.card{
			display: flex;
			align-items: center;
			color: #30373D;
			font-size: 13px;
			line-height: 14px;
			margin-top: 11px;
			.is_agent{
				width:11px;
				height:11px;
				display:block;
				margin-right: 2px;
				margin-bottom: 0;
			}
			
		}
	}
	.right_info{
		height: 273px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 10px;
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.top{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.item{
				width: 105px;
				text-align: center;
				padding: 5px 0 6px;
				color: #fff;
				font-size: 15px;
				line-height: 21px;
				border-radius: 50px;
				background-color: #30373D;
				margin-right: 71px;
				cursor: pointer;
				&:last-child{
					margin-right: 0;
				}
				&.active{
					color:#fff;
					background-color: #FF3300;
				}
			}
		}
		.bottom{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 45px;
			.item{
				min-width: 91px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				color: rgba(48, 55, 61, 0.50);
				background-color: #F7F8FB;
				font-size: 15px;
				line-height: 18px;
				padding: 11px 11px;
				justify-content: center;
				border-radius: 5px;
				margin-right: 30px;
				position: relative;
				cursor: pointer;
				&.active{
					color: #fff;
					background-color: #30373D;
				}
				&:last-child{
					margin-right: 0;
				}
				.number{
					position: absolute;
					right: 0%;
					top: 0%;
					transform: translate(50%,-50%);
					background-color: #FF3300;
					color: #fff;
					font-size: 13px;
					line-height: 13px;
					padding: 2px 5px;
					border-radius: 10px;
				}
				img{
					width: 18px;
					height: 18px;
					display: block;
					margin-right: 5px;
				}
			}
		}
	}
}
.mode_content{
	margin-top: 30px;
}
.types{
	display: flex;
	align-items: center;
	margin-top: 30px;
	justify-content: center;
	.type{
		color: #30373D;
		font-size: 19px;
		line-height: 23px;
		border-bottom: 3px solid transparent;
		margin-right: 58px;
		padding-bottom: 8px;
		cursor: pointer;
		&:last-child{
			margin-right: 0;
		}
		&.active{
			color: #FF3300;
			border-bottom-color: #FF3300;
		}
	}
}
</style>
