<template>
	<div class="demand_goods">
		<div class="goods_list ziyuan" v-loading="loading">
			<div class="top_tab">
				<div class="tabs">
					<div class="item" :class="current_state == 'all' ? 'active':''" @click="chooseMode('all')">所有<div class="line"></div></div>
					<div class="item" :class="current_state == '1' ? 'active':''" @click="chooseMode('1')">已发布<div class="line"></div></div>
					<div class="item" :class="current_state == '0' ? 'active':''" @click="chooseMode('0')">草稿箱<div class="line"></div></div>
				</div>
				<div class="search_area">
					<div class="content">
						<input type="text" v-model="keywords" @keyup.enter="searchGoods()">
						<div class="el-icon-search" @click="searchGoods()"></div>
					</div>
				</div>
			</div>
			<template v-if="resource_list.length">
				<div class="goods_item" v-for="(item,index) in resource_list" :key="index" @click="$router.push('/demand-'+item.goods_id)">
					<div class="goods_info">
						<div class="left">
							<div class="top_info">
								<div class="goods_tit" >{{item.goods_name}}</div>
								<div class="goods_tag">
									<div class="tag" v-if="item.agent_member_id != 0">官验</div>
									<div class="tag">{{item.category_name}}</div>
									<div class="tag" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
								</div>
								<div class="accredits">
									<div class="item" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 2">{{aItem.attr_value_name}}</div>
								</div>
							</div>
							<div class="other_info">
								<!-- <div>已售：{{item.sale_num}}</div> -->
								<div>{{$util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/')}}</div>
							</div>
						</div>
						<div class="right">
							<div class="price">￥<div class="num">{{Number(item.price)}}</div></div>
							<div class="row">
								<div class="kefu" @click.stop="editGoodsInfo(item.goods_id)">编辑</div>
								<div class="kefu" @click.stop="deleteGoods(item.goods_id)">删除</div>
								<div class="kefu" @click.stop="changeGoodsState(item.goods_id,item.goods_state)">{{item.goods_state==1 ?'下架':'上架'}}</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<!-- <div class="empty_list" v-if="!resource_list.length">暂无需求记录</div> -->
			
		</div>
		<div class="pager">
			<el-pagination 
				background 
				:pager-count="5" 
				:total="total" 
				layout="prev,pager,next,jumper,total"
				:current-page.sync="currentPage" 
				:page-size.sync="pageSize" 
				@size-change="handlePageSizeChange" 
				@current-change="handleCurrentPageChange" 
				hide-on-single-page
			></el-pagination>
		</div>
	</div>
	
</template>

<script>
	import { myGoodslist, onGoods, offGoods, deleteGoods } from '@/api/resource.js'
import resource_listVue from './resource_list.vue';
	export default {
		data() {
			return {
				resource_list:[],
				currentPage: 1,
				pageSize: 10,
				total: 0,
				loading: true,
				keywords:'',
				current_state:'all'
			}
		},
		created() {
			this.getResourceList();
		},
		methods: {
			searchGoods(){
				this.refresh();
			},
			chooseMode(mode){
				this.current_state = mode;
				this.refresh()
			},
			deleteGoods(id){
				// this.$confirm('您确定要删除该需求吗?', '提示', {
				// 	confirmButtonText: '确定',
				// 	cancelButtonText: '取消',
				// 	type: 'warning'
				// }).then(() => {
					deleteGoods({
						goods_class:6,
						goods_ids:id
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({
								type: 'success',
								message: '删除成功'
							});
							this.refresh();
						}else{
							this.$message.error(res.message);
						}
					})
					.catch(err=>{
						this.$message.error(err.message);
					})
				// }).catch(() => {
					
				// });
			},
			changeGoodsState(id,state){
				if(state){
					// this.$confirm('您确定要下架该需求吗?', '提示', {
					// 	confirmButtonText: '确定',
					// 	cancelButtonText: '取消',
					// 	type: 'warning'
					// }).then(() => {
						offGoods({
							goods_class:6,
							goods_ids:id
						})
						.then(res=>{
							if(res.code>=0){
								this.$message({
									type: 'success',
									message: '下架成功'
								});
								this.refresh();
							}else{
								this.$message.error(res.message);
							}
						})
						.catch(err=>{
							this.$message.error(err.message);
						})
					// }).catch(() => {
						
					// });
				}else{
					// this.$confirm('您确定要上架该需求吗?', '提示', {
					// 	confirmButtonText: '确定',
					// 	cancelButtonText: '取消',
					// 	type: 'warning'
					// }).then(() => {
						onGoods({
							goods_class:6,
							goods_ids:id
						})
						.then(res=>{
							if(res.code>=0){
								this.$message({
									type: 'success',
									message: '上架成功'
								});
								this.refresh();
							}else{
								this.$message.error(res.message);
							}
						})
						.catch(err=>{
							this.$message.error(err.message);
						})
					// }).catch(() => {
						
					// });
				}
			},
			editGoodsInfo(goods_id){
				this.$router.push('/cms/publish_info?mode=2&goods_id='+goods_id);
			},
			refresh() {
				this.loading = true;
				this.getResourceList();
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			getResourceList() {
				myGoodslist({
					page:this.currentPage,
					page_size:this.pageSize,
					goods_class:6,
					goods_state:this.current_state,
					keywords:this.keywords
				})
				.then(res=>{
					if(res.code>=0){
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.total = res.data.count;
						}
						list.forEach(item=>{
							this.$set(item,'goods_attr_parse',JSON.parse(item.goods_attr_format))
						})
						this.resource_list = list;
						this.loading = false;
					}
				})
				.catch(res => {
					this.loading = false;
				});
			}
		},
	}
</script>
<style lang="scss">
	.demand_goods{
		.pager{
			margin-top: 30px;
			.el-pagination{
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				.btn-prev{
					margin-right: 15px !important;
				}
				.btn-next{
					margin-left: 15px !important;
				}
				.btn-prev,.btn-next{
					min-width: 20px;
					height: 20px;
					margin: 0;
					color: #30373D !important;
					font-weight: bolder !important;
					background-color: transparent;
				}
				.el-pagination__jump{
					margin-left: 20px;
					color: #86909C;
					font-size: 14px;
					line-height: 11px;
					display: flex;
					align-items: center;
					height: auto;
					.el-pagination__editor{
						margin: 0 8px;
						padding: 0;
						height: auto;
						width: auto;
						.el-input__inner{
							height:auto;
							min-width: auto;
							border: none;
							color: #30373D;
							padding: 3px 2px 2px;
							line-height: 14px;
							font-size: 14px;
						}
					}
				}
				.el-pagination__total{
					margin-left: 10px;
					color: #86909C;
					font-size: 14px;
					height: 21px;
					line-height: 21px;
				}
				.el-pager{
					display: flex;
					align-items: center;
					.btn-quicknext,.btn-quickprev{
						height: 21px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: transparent;
					}
					.number{
						padding: 0;
						color: #30373D;
						line-height: 11px;
						height: auto;
						min-width: auto;
						padding: 5px 6px;
						font-size: 14px;
						margin: 0;
						font-weight: 500;
						margin-right: 10px;
						background-color: transparent;
						&:last-child{
							margin-right: 0;
						}
						&:not(.disabled).active{
							color: #30373D;
							font-weight: bolder;
							background-color: #fff;
							border-radius: 1px;
						}
					}
				}
			}
		}
	}
	
</style>
<style lang="scss" scoped>
	.top_tab{
		display: flex;
		align-items: flex-end;
		justify-content: center;
		margin-bottom: 30px;
		.search_area{
			width: 300px;
			.content{
				width: 100%;
				display: flex;
				align-items: center;
				background-color: #fff;
				border-radius: 50px;
				box-sizing: border-box;
				padding: 14px 20px;
				input{
					flex: 1;
					outline: none;
					border: none;
					padding: 0;
					padding-right: 20px;
				}
				input::placeholder{
					font-family: "PingFang SC";
				}
				.el-icon-search{
					font-size: 19px;
					line-height: 19px;
					cursor: pointer;
				}
			}
		}
		.tabs{
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right:50px;
			.item{
				color: #30373D;
				font-size: 19px;
				line-height: 23px;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-right: 58px;
				cursor: pointer;
				&:last-child{
					margin-right: 0;
				}
				&.active{
					color: #FF3300;
					.line{
						background-color: #FF3300;
					}
				}
				.line{
					margin-top: 8px;
					width: 34px;
					height: 3px;
					background-color: transparent;
					border-radius: 5px;
				}
			}
		}
	}
	
	.empty_list{
		font-size: 13px;
		text-align: center;
		padding: 15px 0;
	}
	.goods_list{
		&.ziyuan{
			.goods_item{
				cursor: pointer;
				margin-bottom: 30px;
				display: flex;
				border-radius: 10px;
				background-color: #fff;
				align-items: center;
				.goods_info{
					flex: 1;
					display: flex;
					height: 213px;
					align-items: center;
					padding: 20px 22px 20px 30px;
					box-sizing: border-box;
					.left{
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						height: 100%;
						flex: 1;
						.top_info{
							display:flex;
							flex-direction: column;
							.goods_tit{
								color: #30373D;
								font-size: 19px;
								line-height: 39px;
								font-weight: 600;
								margin-bottom: 10px;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 1;
								-webkit-box-orient: vertical;
							}
							.goods_tag{
								display: flex;
								align-items: center;
								height: 22px;
								overflow: hidden;
								flex-wrap: wrap;
								.tag{
									color: #30373D;
									font-size: 15px;
									line-height: 15px;
									padding: 3px 9px 4px;
									background-color: rgba(48, 55, 61, 0.05);
									border-radius: 3px;
									margin-right: 10px;
									&:last-child{
										margin-right: 0;
									}
								}
							}
							.accredits{
								display: flex;
								align-items: center;
								flex-wrap: wrap;
								height: 64px;
								overflow: hidden;
								align-content: flex-start;
								.item{
									color: #30373D;
									font-size: 15px;
									line-height: 20px;
									padding: 0 4px;
									border: 1px solid rgba(48, 55, 61, 0.20);
									border-radius: 3px;
									margin-right: 10px;
									margin-top: 10px;
									box-sizing: border-box;
								}
							}
						}
						.other_info{
							display: flex;
							align-items: center;
							div{
								color: #999;
								font-size: 13px;
								line-height: 22px;
								margin-right: 30px;
								&:last-child{
									margin-right: 0;
								}
							}
						}
					}
					.right{
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: flex-end;
						align-items: flex-end;
						.price{
							display: flex;
							align-items: baseline;
							color: #FF3300;
							font-size: 13px;
							line-height: 39px;
							margin-bottom: 19px;
							.num{
								font-size: 19px;
								font-weight: 600;
							}
						}
						.row{
							display: flex;
							flex-direction: column;
							align-items: flex-end;
							.time{
								color: #999;
								font-size: 13px;
								line-height: 18px;
							}
							.kefu{
								min-width: 105px;
								text-align: center;
								margin-bottom: 10px;
								color: #30373D;
								font-size: 13px;
								line-height: 15px;
								padding: 7px 0;
								border-radius: 60px;
								background-color: #E0E2EA;
								margin-left: 52px;
								&:last-child{
									margin-bottom: 0;
								}
								&:hover{
									color: #fff;
									background-color: #30373D;
								}
							}
						}
					}
				}
			}
			
		}
	}
</style>