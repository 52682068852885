import { orderClose, orderPay, serviceComplete, orderFinalPay, correctComplete, applyRefund, cancelRefund, agreeRefund, refuseRefund, orderWechatPay, orderAliPay } from '@/api/resource.js'
export default {
    methods: {
		refuseRefund(orderId,callback){
			refuseRefund({
				order_id:orderId
			})
			.then(res=>{
				if(res.code>=0){
					typeof callback == "function" && callback();
				}else{
					this.$message.error(res.message)
				}
			})
			.catch(err=>{
				this.$message.error(err.message)
			})
		},
		agreeRefund(orderId,callback){
			agreeRefund({
				order_id:orderId
			})
			.then(res=>{
				if(res.code>=0){
					typeof callback == "function" && callback();
				}else{
					this.$message.error(res.message)
				}
			})
			.catch(err=>{
				this.$message.error(err.message)
			})
		},
		cancelRefund(orderId,callback){
			cancelRefund({
				order_id:orderId
			})
			.then(res=>{
				if(res.code>=0){
					this.$message({
						message: "取消成功",
						type: "success"
					})
					typeof callback == "function" && callback();
				}else{
					this.$message.error(res.message)
				}
			})
			.catch(err=>{
				this.$message.error(err.message)
			})
		},
		applyRefund(orderId,callback){
			applyRefund({
				order_id:orderId
			})
			.then(res=>{
				if(res.code>=0){
					this.$message({
						message: "申请成功",
						type: "success"
					})
					typeof callback == "function" && callback();
				}else{
					this.$message.error(res.message)
				}
			})
			.catch(err=>{
				this.$message.error(err.message)
			})
		},
		correctComplete(orderId,callback){
			correctComplete({
				order_id:orderId
			})
			.then(res=>{
				if(res.code>=0){
					this.$message({
						message: "提交成功",
						type: "success"
					})
					typeof callback == "function" && callback();
				}else{
					this.$message.error(res.message)
				}
			})
			.catch(err=>{
				this.$message.error(err.message)
			})
		},
		orderFinalPay(orderId,callback){
			orderFinalPay({
				order_id:orderId
			})
			.then(res=>{
				if(res.code>=0){
					this.$message({
						message: "支付完成",
						type: "success"
					})
					typeof callback == "function" && callback()
				}else{
					this.$message.error(res.message)
					
				}
			})
			.catch(err=>{
				this.$message.error(err.message)
				if( err.error_code == 'ACCOUNT_EMPTY' ){
					setTimeout(()=>{
						this.$router.push('/member/index?isRecharge=1&my_current=9')
					},1000)
				}
			})
		},
		serviceComplete(orderId,callback){
			serviceComplete({
				order_id:orderId
			})
			.then(res=>{
				if(res.code>=0){
					this.$message({
						message: "服务已完成",
						type: "success"
					})
					typeof callback == "function" && callback()
				}else{
					this.$message.error(res.message)
				}
			})
			.catch(err=>{
				this.$message.error(err.message)
			})
		},
		closeOrder(orderId,callback){
			// this.$confirm("您确定要关闭该订单吗？", "提示", {
			// 	confirmButtonText: "确定",
			// 	cancelButtonText: "取消",
			// 	type: "warning"
			// }).then(()=>{
				orderClose({
					order_id:orderId
				})
				.then(res=>{
					if(res.code>=0){
						this.$message({
							message: "订单关闭成功",
							type: "success"
						})
						typeof callback == "function" && callback()
					}else{
						this.$message.error(res.message)
					}
				})
				.catch(err=>{
					this.$message.error(err.message)
				})
			// })
		},
		payOrder(orderId,callback){
			orderPay({
				order_id:orderId
			})
			.then(res=>{
				if(res.code>=0){
					this.$message({
						message: "订单支付成功",
						type: "success"
					})
					typeof callback == "function" && callback()
				}else{
					this.$message.error(res.message)
				}
			})
			.catch(err=>{
				this.$message.error(err.message);
				if( err.error_code == 'ACCOUNT_EMPTY' ){
					setTimeout(()=>{
						this.$router.push('/member/index?isRecharge=1&my_current=9')
					},1000)
				}
			})
		},
		wechatPayOrder(orderId,callback){
			orderWechatPay({
				order_id:orderId
			})
			.then(res=>{
				if(res.code>=0){
					typeof callback == "function" && callback(res)
				}else{
					this.$message.error(res.message)
				}
			})
			.catch(err=>{
				this.$message.error(err.message)
			})
		},
		aliPayOrder(orderId,callback){
			
			orderAliPay({
				order_id:orderId,
				app_type:'h5',
				app_type_name:'H5'
			})
			.then(res=>{
				if(res.code>=0){
					typeof callback == "function" && callback(res)
				}else{
					this.$message.error(res.message)
				}
			})
			.catch(err=>{
				this.$message.error(err.message)
			})
		},
	}
}
