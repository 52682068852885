<template>
	<div class="security_code">
		<div class="security_area">
			<div class="form">
				<div class="form_item">
					<div class="tit">防伪码</div>
					<input class="shuru" type="text" v-model="code" @blur="searchCodeInfo()" @keyup.enter="searchCodeInfo()">
				</div>
				<div class="form_item">
					<div class="tit">品牌</div>
					<div class="right">{{code_info ? code_info.brand_name : '无'}}</div>
				</div>
				<div class="form_item">
					<div class="tit">商品</div>
					<div class="right">{{code_info ? code_info.goods_name : '无'}}</div>
				</div>
				<div class="form_item">
					<div class="tit">生产商</div>
					<div class="right">{{code_info ? code_info.producer : '无'}}</div>
				</div>
				<div class="form_item">
					<div class="tit">查次</div>
					<div class="right">{{code_info ? code_info.check_num+'次' : '无'}}</div>
				</div>
			</div>
			<div class="actions">
				<div class="action" @click="copyInfo()">复制</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { checkAntifakecode } from '@/api/accredit.js'
	import { categoryList } from "@/api/resource.js"
	import topSearch from '@/components/top_search';
	import { mapGetters } from "vuex";
	export default {
		data() {
			return {
				current_mode:1,
				current_category:0,
				category_id:'',
				all_category:[],
				code:'',
				search_code:'',
				code_info:null,
				flag:false
			}
		},
		computed: {
			...mapGetters(["defaultHeadImage", "addonIsExit", "defaultGoodsImage", "member", "siteInfo", "cartCount","city","token"]),
		},
		components: {
			topSearch,
		},
		created() {
			// this.getCategoryList();
		},
		methods: {
			copyInfo(){
				if(!this.code_info){
					this.$message.error('请先查询防伪码信息');
					return;
				}
				var str = this.code_info.brand_name+'\r\n'+this.code_info.goods_name+'\r\n'+this.code_info.producer+'\r\n'+this.code_info.check_num+'次';
				this.$util.copy(str);
			},
			searchCodeInfo(){
				if(!this.code){
					// this.$message.error('请填写搜索的防伪码');
					return;
				}
				if(!this.token){
					this.$message.error('请先去登录');
					setTimeout(()=>{
						this.$router.push({path:'/login',query:{redirect:'/cms/security_code'}})
					},800)
					return;
				}
				
				if( this.search_code.trim() == this.code.trim()){
					return;
				}
				if(this.flag) return;
				this.flag = true;
				checkAntifakecode({
					code:this.code
				})
				.then(res=>{
					if(res.code>=0){
						this.code_info = res.data;
						this.search_code = this.code;
						if(res.data){
						}else{
							this.$message.error('暂无相关防伪信息')
						}
					}else{
						this.$message.error(res.message);
					}
					this.flag = false;
				})
				.catch(err=>{
					this.$message.error(err.message);
					this.flag = false;
				})
			},
			chooseCategory(category_id,index,class_id){
				this.goods_loading = true;
				this.current_category = index;
				this.category_id = category_id;
				let lastSpot = this.$refs.scrollBox.scrollLeft;
				const nextSpace = 50; //每次移动距离
				let scrollItemTimer = setInterval(() => {
					this.$nextTick(() => {
						let offsetWidth = this.$refs.scrollItem[this.current_category].offsetWidth;
						let scrollLeft = this.$refs.scrollItem[this.current_category].offsetLeft;
						const containWidth = this.$refs.scrollBox.offsetWidth;
						if(this.$refs.scrollItem[0].offsetLeft == 0){
							var resultSpot = scrollLeft + offsetWidth / 2 - containWidth / 2;
						}else{
							var resultSpot = scrollLeft + offsetWidth / 2 - containWidth / 2 - this.$refs.scrollItem[0].offsetLeft;
						}
						if (Math.abs(lastSpot - resultSpot) < nextSpace) {
							clearInterval(scrollItemTimer);
						}
						if (resultSpot >= lastSpot) {
							lastSpot = lastSpot + nextSpace;
						} else {
							lastSpot = lastSpot - nextSpace;
						}
						this.$refs.scrollBox.scrollTo(lastSpot, 0);
					});
				}, 15);
			},
			getCategoryList(){
				categoryList({
					goods_type:this.current_mode==1?'resource':'demand'
				})
				.then(res=>{
					if(res.code>=0){
						this.all_category = [{'category_id':'','category_name':'推荐'}]
						this.all_category.push(...this.$util.deepClone(res.data));
						this.category_id = this.all_category[0].category_id;
						this.current_category = 0;
					}
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.security_code{
		// padding-top: 30px;
		.security_area{
			background-color: #fff;
			border-radius: 10px;
			margin-top: 30px;
			.actions{
				padding: 30px 0;
				border-top: 1px solid rgba(48, 55, 61, 0.10);
				display: flex;
				align-items: center;
				justify-content: center;
				.action{
					color: rgba(48, 55, 61, 0.50);
					font-size: 15px;
					line-height: 21px;
					height: 32px;
					padding: 0 43px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #F7F8FB;
					border-radius: 5px;
					cursor: pointer;
					&:hover{
						color: #fff;
						background-color: #30373D;
					}
				}
			}
			.form{
				padding: 30px 156px;
				.form_item{
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					&:last-child{
						margin-bottom: 0;
					}
					.tit{
						color: #30373D;
						font-size: 15px;
						line-height: 21px;
						min-width: 46px;
						margin-right: 41px;
					}
					.shuru{
						width: 100%;
						outline: none;
						border: none;
						padding: 0;
						text-align: center;
						color: #30373D;
						font-size: 15px;
						line-height: 21px;
						padding: 10px;
						border-radius: 5px;
						background-color: #F7F8FB;
						&::placeholder{
							font-family: "PingFang SC";
							color: rgba(48, 55, 61, 0.50);
						}
					}
					.right{
						flex: 1;
						color: #30373D;
						font-size: 15px;
						line-height: 21px;
						padding: 10px;
						text-align: center;
						border-radius: 5px;
						background-color: rgba(247, 248, 251, 0.20);
					}
				}
			}
		}
		.category_lists{
			width: 100%;
			margin-top: 30px;
			display: flex;
			align-items: center;
			overflow: hidden;
			.item{
				white-space: nowrap;
				color: #30373D;
				font-size: 19px;
				line-height: 23px;
				margin-right: 58px;
				display: flex;
				align-items: center;
				flex-direction: column;
				cursor: pointer;
				&.active{
					color: #FF3300;
				}
				.line{
					margin-top: 9px;
					width: 34px;
					height: 3px;
					border-radius: 20px;
					background-color: transparent;
					&.active{
						background-color: #FF3300;
					}
				}
			}
		}
	}
</style>