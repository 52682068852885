<template>
	<div class="cart">
		<div class="cart_list" v-loading="loading">
			<div class="tabs">
				<div class="item" :class="type == 'cart' ? 'active':''" @click="changeGoodsType('cart')">购物车<div class="line"></div></div>
				<div class="item" :class="type == 'collect' ? 'active':''" @click="changeGoodsType('collect')">喜欢<div class="line"></div></div>
				<div class="item" :class="type == 'footprint' ? 'active':''" @click="changeGoodsType('footprint')">足迹<div class="line"></div></div>
			</div>
			<template v-if="type=='cart'">
				<div class="goods_item" v-if="cart_list.length" v-for="(item,index) in cart_list" :key="index" @click="toGoodsDetail(item.goods_id)">
					<img class="goods_img" :src="$img(item.goods_image.split(',')[0],{ size: 'mid' })" alt="" @error="item.goods_image = defaultGoodsImage">
					<div class="goods_info">
						<div class="left">
							<div class="goods_tit">{{item.goods_name}}</div>
							<div class="goods_tag">
								<div class="tag" v-if="item.agent_member_id != 0">官验</div>
								<div class="tag">{{item.category_name}}</div>
								<div class="tag" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
							</div>
							<div class="accredits">
								<div class="item" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 2">{{aItem.attr_value_name}}</div>
							</div>
						</div>
						<div class="right">
							<div class="price">￥<div class="num">{{Number(item.price)}}</div></div>
							<div class="row first">
								<div class="time">{{$util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/')}}</div>
								<div class="kefu" @click.stop="toViewChat(item.member_info.member_id)">客服</div>
							</div>
							<div class="row">
								<div class="info">
									<img class="logo" @click.stop="toMemberDetail(item.member_info.member_id)" v-if="item.member_info" :src="$img(item.member_info.headimg)" @error="item.member_info.headimg = defaultHeadImage" alt="">
									<div class="iconfont1 icon-xihuan" :class="item.is_collect?'active':''" @click.stop="collectGoods(item)"></div>
									<img src="@/assets/images/newImg/message.png" @click.stop="evaluateGoods(item,'comment')" />
									<img src="@/assets/images/newImg/bargain.png" @click.stop="evaluateGoods(item,'bargain')" />
									<div class="iconfont1 icon-gouwuche purchase" :class="item.is_cart?'active':''" @click.stop="purchaseGoods(item)"></div>
								</div>
								<div class="kefu wx">微信
									<img v-if="item.member_info.wechat_personal_qrcode" :src="$img(item.member_info.wechat_personal_qrcode)" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="empty_cart" v-if="!cart_list.length">暂无加购资源</div> -->
			</template>
			<template v-if="type=='collect'">
				<div class="goods_item" v-if="collect_list.length" v-for="(item,index) in collect_list" :key="index" @click="toGoodsDetail(item.goods_id)">
					<img class="goods_img" :src="$img(item.goods_image.split(',')[0])" alt="" @error="item.goods_image = defaultGoodsImage">
					<div class="goods_info">
						<div class="left">
							<div class="goods_tit">{{item.goods_name}}</div>
							<div class="goods_tag">
								<div class="tag" v-if="item.agent_member_id != 0">官验</div>
								<div class="tag">{{item.category_name}}</div>
								<div class="tag" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
							</div>
							<div class="accredits">
								<div class="item" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 2">{{aItem.attr_value_name}}</div>
							</div>
						</div>
						<div class="right">
							<div class="price">￥<div class="num">{{Number(item.price)}}</div></div>
							<div class="row first">
								<div class="time">{{$util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/')}}</div>
								<div class="kefu" @click.stop="toViewChat(item.member_info.member_id)">客服</div>
							</div>
							<div class="row">
								<div class="info">
									<img class="logo" @click.stop="toMemberDetail(item.member_info.member_id)" v-if="item.member_info" :src="$img(item.member_info.headimg)" @error="item.member_info.headimg = defaultHeadImage" alt="">
									<div class="iconfont1 icon-xihuan" :class="item.is_collect?'active':''" @click.stop="collectGoods(item)"></div>
									<img src="@/assets/images/newImg/message.png" @click.stop="evaluateGoods(item,'comment')" />
									<img src="@/assets/images/newImg/bargain.png" @click.stop="evaluateGoods(item,'bargain')" />
									<div class="iconfont1 icon-gouwuche purchase" :class="item.is_cart?'active':''" @click.stop="purchaseGoods(item)"></div>
								</div>
								<div class="kefu wx">微信
									<img v-if="item.member_info.wechat_personal_qrcode" :src="$img(item.member_info.wechat_personal_qrcode)" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="empty_cart" v-if="!collect_list.length">暂无收藏</div> -->
			</template>
			<template v-if="type=='footprint'">
				<div class="goods_item" v-if="foot_list.length" v-for="(item,index) in foot_list" :key="index" @click="toGoodsDetail(item.goods_id)">
					<img class="goods_img" :src="$img(item.goods_image.split(',')[0])" alt="" @error="item.goods_image = defaultGoodsImage">
					<div class="goods_info">
						<div class="left">
							<div class="goods_tit">{{item.goods_name}}</div>
							<div class="goods_tag">
								<div class="tag" v-if="item.agent_member_id != 0">官验</div>
								<div class="tag">{{item.category_name}}</div>
								<div class="tag" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
							</div>
							<div class="accredits">
								<div class="item" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 2">{{aItem.attr_value_name}}</div>
							</div>
						</div>
						<div class="right">
							<div class="price">￥<div class="num">{{Number(item.price)}}</div></div>
							<div class="row first">
								<div class="time">{{$util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/')}}</div>
								<div class="kefu" @click.stop="toViewChat(item.member_info.member_id)">客服</div>
							</div>
							<div class="row">
								<div class="info">
									<img class="logo" @click.stop="toMemberDetail(item.member_info.member_id)" v-if="item.member_info" :src="$img(item.member_info.headimg)" @error="item.member_info.headimg = defaultHeadImage" alt="">
									<div class="iconfont1 icon-xihuan" :class="item.is_collect?'active':''" @click.stop="collectGoods(item)"></div>
									<img src="@/assets/images/newImg/message.png" @click.stop="evaluateGoods(item,'comment')" />
									<img src="@/assets/images/newImg/bargain.png" @click.stop="evaluateGoods(item,'bargain')" />
									<div class="iconfont1 icon-gouwuche purchase" :class="item.is_cart?'active':''" @click.stop="purchaseGoods(item)"></div>
								</div>
								<div class="kefu wx">微信
									<img v-if="item.member_info.wechat_personal_qrcode" :src="$img(item.member_info.wechat_personal_qrcode)" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="empty_cart" v-if=" !foot_list.length">暂无足迹</div> -->
			</template>
		</div>
		<div class="pager">
			<el-pagination 
				background 
				:pager-count="5" 
				:total="total" 
				layout="prev,pager,next,jumper,total"
				:current-page.sync="currentPage" 
				:page-size.sync="pageSize" 
				@size-change="handlePageSizeChange" 
				@current-change="handleCurrentPageChange" 
				hide-on-single-page
			></el-pagination>
		</div>
		<servicerMessage ref="servicerMessage" class="kefu" :toUid="toUid"></servicerMessage>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { cartList, deleteCollect, addCollect, deleteCart, addCart, collectList, footprintList } from '@/api/resource.js'
	import servicerMessage from "@/components/message/servicerMessage"
	export default {
		props: {
			cartType: {
				type: String,
				default: ''
			},
		},
		components: {
			servicerMessage
		},
		data() {
			return {
				cart_list:[],
				currentPage: 1,
				pageSize: 10,
				total: 0,
				loading:true,
				type:'cart',
				collect_list:[],
				foot_list:[],
				toUid:'',
			}
		},
		computed: {
			...mapGetters(['defaultGoodsImage', 'member',"defaultHeadImage","token"])
		},
		created(){
			if(this.cartType){
				this.type = this.cartType;
				if(this.type=='collect') this.getCollectList();
				if(this.type=='footprint') this.getFootPrint();
			}else{
				this.getCartList();
			}
		},
		methods: {
			toViewChat(id=''){
				if(!this.token){
					this.$message.warning('请先进行登录');
					return;
				}
				if(id){
					this.toUid = 'member_'+id;
				}else{
					this.toUid = 'member_'+this.goods_info.member_info.member_id;
				}
				this.$refs.servicerMessage.show();
			},
			evaluateGoods(goodsItem,mode){
				this.$router.pushToTab('/resource-'+goodsItem.goods_id+'?status='+mode)
			},
			toMemberDetail(id){
				this.$router.push('/personal-'+id)
			},
			getFootPrint(){
				footprintList({
					page:this.currentPage,
					page_size:this.pageSize,
				})
				.then(res=>{
					if(res.code>=0){
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.total = res.data.count;
						}
						list.forEach(item=>{
							this.$set(item,'goods_attr_parse',JSON.parse(item.goods_attr_format))
						})
						this.foot_list = list;
						this.loading = false;
					}
				})
			},
			getCollectList(){
				collectList({
					page:this.currentPage,
					page_size:this.pageSize,
				})
				.then(res=>{
					if(res.code>=0){
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.total = res.data.count;
						}
						list.forEach(item=>{
							this.$set(item,'goods_attr_parse',JSON.parse(item.goods_attr_format))
						})
						this.collect_list = list;
						this.loading = false;
					}
				})
			},
			toGoodsDetail(id){
				this.$router.push('/resource-'+id)
			},
			purchaseGoods(info){
				if(!this.token){
					this.$message.error('请先进行登录');
					return;
				}
				if(this.type=='cart'){
					deleteCart({
						goods_id:info.goods_id
					})
					.then(res=>{
						if(res.code>=0){
							info.is_cart = 0;
							this.refresh();
						}else{
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}else{
					if(info.is_cart){
						deleteCart({
							goods_id:info.goods_id
						})
						.then(res=>{
							if(res.code>=0){
								info.is_cart = 0;
							}else{
								this.$message.error(res.message)
							}
						})
						.catch(err=>{
							this.$message.error(err.message)
						})
					}else{
						addCart({
							goods_id:info.goods_id
						})
						.then(res=>{
							if(res.code>=0){
								info.is_cart = 1;
							}else{
								this.$message.error(res.message)
							}
						})
						.catch(err=>{
							this.$message.error(err.message)
						})
					}
				}
				
			},
			collectGoods(info){
				if(!this.token){
					this.$message.error('请先进行登录');
					return;
				}
				if(this.type == 'collect'){
					deleteCollect({
						goods_id:info.goods_id
					})
					.then(res=>{
						if(res.code>=0){
							info.is_collect = 0;
							this.refresh()
						}else{
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}else{
					if(info.is_collect){
						deleteCollect({
							goods_id:info.goods_id
						})
						.then(res=>{
							if(res.code>=0){
								info.is_collect = 0;
								
							}else{
								this.$message.error(res.message)
							}
						})
						.catch(err=>{
							this.$message.error(err.message)
						})
					}else{
						addCollect({
							goods_id:info.goods_id
						})
						.then(res=>{
							if(res.code>=0){
								info.is_collect = 1;
							}else{
								this.$message.error(res.message)
							}
						})
						.catch(err=>{
							this.$message.error(err.message)
						})
					}
				}
				
			},
			refresh() {
				this.loading = true;
				switch (this.type){
					case 'cart':
						this.getCartList();
						break;
					case 'collect':
						this.getCollectList();
						break;
					case 'footprint':
						this.getFootPrint();
						break;
					default:
						break;
				}
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			getCartList(){
				cartList({
					page:this.currentPage,
					page_size:this.pageSize,
				})
				.then(res=>{
					if(res.code>=0){
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.total = res.data.count;
						}
						list.forEach(item=>{
							this.$set(item,'goods_attr_parse',JSON.parse(item.goods_attr_format))
						})
						this.cart_list = list;
						this.loading = false;
					}
				})
			},
			changeGoodsType(type){
				if(this.type == type) return;
				this.type = type;
				this.currentPage = 1;
				this.total = 0;
				this.loading = true;
				switch (type){
					case 'collect':
						this.getCollectList();
						break;
					case 'cart':
						this.getCartList();
						break;
					case 'footprint':
						this.getFootPrint();
						break;
					default:
						break;
				}
			},
		},
	}
</script>
<style lang="scss">
	.cart{
		.pager{
			margin-top: 30px;
			.el-pagination{
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				.btn-prev{
					margin-right: 15px !important;
				}
				.btn-next{
					margin-left: 15px !important;
				}
				.btn-prev,.btn-next{
					min-width: 20px;
					height: 20px;
					margin: 0;
					color: #30373D !important;
					font-weight: bolder !important;
					background-color: transparent;
				}
				.el-pagination__jump{
					margin-left: 20px;
					color: #86909C;
					font-size: 14px;
					line-height: 11px;
					display: flex;
					align-items: center;
					height: auto;
					.el-pagination__editor{
						margin: 0 8px;
						padding: 0;
						height: auto;
						width: auto;
						.el-input__inner{
							height:auto;
							min-width: auto;
							border: none;
							color: #30373D;
							padding: 3px 2px 2px;
							line-height: 14px;
							font-size: 14px;
							background-color: #fff;
						}
					}
				}
				.el-pagination__total{
					margin-left: 10px;
					color: #86909C;
					font-size: 14px;
					height: 21px;
					line-height: 21px;
				}
				.el-pager{
					display: flex;
					align-items: center;
					.btn-quicknext,.btn-quickprev{
						height: 21px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: transparent;
					}
					.number{
						padding: 0;
						color: #30373D;
						line-height: 11px;
						height: auto;
						min-width: auto;
						padding: 5px 6px;
						font-size: 14px;
						margin: 0;
						font-weight: 500;
						margin-right: 10px;
						background-color: transparent;
						&:last-child{
							margin-right: 0;
						}
						&:not(.disabled).active{
							color: #30373D;
							font-weight: bolder;
							background-color: #fff;
							border-radius: 1px;
						}
					}
				}
			}
		}
	}
</style>
<style lang="scss" scoped>
.empty_cart{
	text-align: center;
}
.cart_list{
	.tabs{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 37px;
		.item{
			color: #30373D;
			font-size: 19px;
			line-height: 23px;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-right: 58px;
			cursor: pointer;
			&:last-child{
				margin-right: 0;
			}
			&.active{
				color: #FF3300;
				.line{
					background-color: #FF3300;
				}
			}
			.line{
				margin-top: 8px;
				width: 34px;
				height: 3px;
				background-color: transparent;
				border-radius: 5px;
			}
		}
	}
	.goods_item{
		cursor: pointer;
		margin-bottom: 30px;
		display: flex;
		border-radius: 10px;
		background-color: #fff;
		align-items: center;
		.goods_img{
			border-radius: 10px;
			width: 197px;
			height: 197px;
			display: block;
			margin-right: 30px;
			object-fit: cover;
		}
		.goods_info{
			flex: 1;
			display: flex;
			height: 197px;
			align-items: center;
			padding: 20px 22px 20px 0;
			box-sizing: border-box;
			.left{
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 100%;
				flex: 1;
				padding-right: 20px;
				.goods_tit{
					color: #30373D;
					font-size: 19px;
					line-height: 39px;
					font-weight: 600;
					margin-bottom: 10px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
				.goods_tag{
					display: flex;
					align-items: center;
					height: 22px;
					overflow: hidden;
					flex-wrap: wrap;
					.tag{
						color: #30373D;
						font-size: 15px;
						line-height: 15px;
						padding: 3px 9px 4px;
						background-color: rgba(48, 55, 61, 0.05);
						border-radius: 3px;
						margin-right: 10px;
						&:last-child{
							margin-right: 0;
						}
					}
				}
				.accredits{
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					height: 64px;
					overflow: hidden;
					align-content: flex-start;
					.item{
						color: #30373D;
						font-size: 15px;
						line-height: 20px;
						padding: 0 4px;
						border: 1px solid rgba(48, 55, 61, 0.20);
						border-radius: 3px;
						margin-right: 10px;
						margin-top: 10px;
						box-sizing: border-box;
					}
				}
			}
			.right{
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-end;
				.price{
					display: flex;
					align-items: baseline;
					color: #FF3300;
					font-size: 13px;
					line-height: 39px;
					.num{
						font-size: 19px;
						font-weight: 600;
					}
				}
				.row{
					display: flex;
					align-items: flex-end;
					&.first{
						margin-bottom: 10px;
						margin-top: 27px;
					}
					.info{
						display: flex;
						align-items: center;
						img{
							width: 18px;
							height: 18px;
							display: block;
							margin-right: 20px;
						}
						.iconfont1{
							font-size: 18px;
							line-height: 1;
							margin-right: 20px;
							color: #999;
							&.active{
								color: #FF3300;
							}
						}
						.logo{
							border-radius: 50%;
							object-fit: cover;
						}
						.last{
							margin-right: 0;
						}
						.purchase{
							margin-right: 0;
							font-size: 15px !important;
							line-height: 15px !important;
						}
					}
					.time{
						color: #999;
						font-size: 13px;
						line-height: 18px;
					}
					.kefu{
						color: #fff;
						font-size: 13px;
						line-height: 18px;
						padding: 7px 40px;
						border-radius: 60px;
						background-color: #30373D;
						margin-left: 52px;
						position: relative;
						&.wx{
							color: #1BCF42;
							background-color: rgba(27, 207, 66, 0.20);
							&:hover{
								img{
									display: block;
								}
							}
						}
						img{
							position: absolute;
							top: 100%;
							left: 50%;
							transform: translateX(-50%);
							display: none;
						}
					}
				}
			}
		}
	}
}
</style>