<template>
	<div class="buyer_contract" v-loading="loading">
		<div class="contract_tabs">
			<div>估价</div>
			<div>公司名称</div>
			<div>发布时间</div>
		</div>
		<div class="contract_list" v-if="goods_estimate.length">
			<div class="conract_item" v-for="(item,index) in goods_estimate" :key="index">
				<div>{{Number(item.price)}}</div>
				<div>{{item.site_name}}</div>
				<div>{{item.update_time ? $util.timeStampTurnTime(item.update_time).split(' ')[0].replaceAll('-','/') : $util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/')}}</div>
			</div>
		</div>
		<div class="empty_list" v-else>
			暂无估价信息
		</div>
		<div class="pager">
			<el-pagination 
				background 
				:pager-count="5" 
				:total="total" 
				prev-text="上一页" 
				next-text="下一页" 
				:current-page.sync="currentPage" 
				:page-size.sync="pageSize" 
				@size-change="handlePageSizeChange" 
				@current-change="handleCurrentPageChange" 
				hide-on-single-page
			></el-pagination>
		</div>
	</div>
</template>

<script>
	import { goodsEstimateLis } from '@/api/resource';
	export default {
		data() {
			return {
				goods_estimate:[],
				loading:true,
				currentPage: 1,
				pageSize: 10,
				total: 0,
			}
		},
		props: {
			goodsId: {
				type: [Number,String],
				default: ''
			},
		},
		created() {
			this.getGoodsEstimate();
		},
		methods: {
			getGoodsEstimate() {
				goodsEstimateLis({
					page:this.currentPage,
					page_size:this.pageSize,
					goods_id:this.goodsId
				})
				.then(res=>{
					if(res.code>=0){
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.total = res.data.count;
						}
						this.goods_estimate = list;
						this.loading = false;
					}
				})
			},
			refresh() {
				this.loading = true;
				this.getGoodsEstimate();
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
		},
	}
</script>

<style lang="scss">
	.empty_list{
		text-align: center;
		margin-top: 20px;
	}
	.buyer_contract{
		padding-top: 10px;
		.contract_tabs{
			display: flex;
			align-items: center;
			background-color: #fff;
			border-radius: 10px;
			padding: 20px 11px;
			margin-bottom: 17px;
			div{
				color: #30373D;
				font-size: 15px;
				line-height: 18px;
				font-weight: 600;
				box-sizing: border-box;
				flex:1;
			}
		}
		.contract_list{
			.conract_item{
				display: flex;
				align-items: center;
				padding: 17px 11px;
				background-color: #fff;
				border-radius: 8px;
				margin-bottom: 17px;
				&:last-child{
					margin-bottom: 0;
				}
				div{
					color: #30373D;
					box-sizing: border-box;
					font-size: 15px;
					line-height: 18px;
					flex:1;
					&:last-child{
						width: 15%;
						cursor: pointer;
						&:hover{
							color: #FF3300;
						}
					}
				}
			}
		}
		
	}
</style>