<template>
	<div class="bid_list">
		<div class="tabs">
			<div class="item" :class="status == 0 ? 'active':''" @click="changeGoodsType(0)">投标<div class="line"></div></div>
			<div class="item" :class="status == 1 ? 'active':''" @click="changeGoodsType(1)">中标<div class="line"></div></div>
		</div>
		<div class="goods_list demand" v-loading="loading">
			<template v-if="tender_list.length">
				<div class="goods_item" v-for="(item,index) in tender_list" :key="index" @click="toGoodsDetail(item.goods_id)">
					<div class="goods_info">
						<div class="left">
							<div class="goods_tit">{{item.goods_name}}</div>
							<div class="goods_tag">
								<div class="tag" v-if="item.agent_member_id != 0">官验</div>
								<div class="tag">{{item.category_name}}</div>
								<div class="tag" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
							</div>
							<div class="accredits">
								<div class="item" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 2">{{aItem.attr_value_name}}</div>
							</div>
						</div>
						<div class="right">
							<div class="price">￥<div class="num">{{item.tender_price}}</div></div>
							<div class="row first">
								<div class="time">{{$util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/')}}</div>
								<div class="kefu" @click.stop="toViewChat(item.member_info.member_id)">客服</div>
							</div>
							<div class="row">
								<div class="info">
									<img class="logo" @click.stop="toMemberInfo(item.member_info.member_id)" v-if="item.member_info" :src="$img(item.member_info.headimg)" @error="item.member_info.headimg = defaultHeadImage" alt="">
									<img src="@/assets/images/newImg/message.png" @click.stop="evaluateGoods(item,'comment')" />
									<img src="@/assets/images/newImg/invitation.png" alt="" @click.stop="evaluateGoods(item,'tender')">
									<img class="last" src="@/assets/images/newImg/contact.png" />
								</div>
								<div class="kefu wx">微信
									<img v-if="item.member_info.wechat_personal_qrcode" :src="$img(item.member_info.wechat_personal_qrcode)" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<!-- <div v-else class="empty_tender">
				暂无记录
			</div> -->
			<div class="pager">
				<el-pagination 
					background 
					:pager-count="5" 
					:total="total" 
					:current-page.sync="currentPage"
					layout="prev,pager,next,jumper,total"
					:page-size.sync="pageSize" 
					@size-change="handlePageSizeChange" 
					@current-change="handleCurrentPageChange" 
					hide-on-single-page
				></el-pagination>
			</div>
		</div>
		<servicerMessage ref="servicerMessage" class="kefu" :toUid="toUid"></servicerMessage>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { sellerGoodsTenderList } from '@/api/resource';
	import servicerMessage from "@/components/message/servicerMessage"
	export default {
		data() {
			return {
				status:0,
				currentPage: 1,
				pageSize: 5,
				total: 0,
				loading:true,
				tender_list:[],
				toUid:'',
			}
		},
		components: {
			servicerMessage
		},
		created() {
			this.getSellerGoodsTenderList();
		},
		computed: {
			...mapGetters(['defaultGoodsImage', 'member',"defaultHeadImage","token"])
		},
		methods: {
			refresh(){
				this.loading = true;
				this.getSellerGoodsTenderList();
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			evaluateGoods(goodsInfo,mode){
				this.$router.pushToTab('/demand-'+goodsInfo.goods_id+'?status='+mode)
			},
			toMemberInfo(id){
				this.$router.push('/personal-'+id);
			},
			toViewChat(id){
				if(!this.token){
					this.$message.warning('请先进行登录');
					return;
				}
				this.toUid = 'member_'+id;
				this.$refs.servicerMessage.show();
			},
			toGoodsDetail(goodsId){
				this.$router.pushToTab('/demand-'+goodsId)
			},
			getSellerGoodsTenderList(){
				sellerGoodsTenderList({
					page:this.currentPage,
					page_size:this.pageSize,
					status:this.status
				})
				.then(res=>{
					if(res.code>=0){
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.total = res.data.count;
						}
						list.forEach(item=>{
							this.$set(item,'goods_attr_parse',JSON.parse(item.goods_attr_format))
						})
						this.tender_list = list;
						this.loading = false;
					}
				})
			},
			changeGoodsType(status){
				this.status = status;
				this.currentPage = 1;
				this.refresh();
			},
		},
	}
</script>
<style lang="scss">
	.bid_list{
		.pager{
			margin-top: 30px;
			.el-pagination{
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				.btn-prev{
					margin-right: 15px !important;
				}
				.btn-next{
					margin-left: 15px !important;
				}
				.btn-prev,.btn-next{
					min-width: 20px;
					height: 20px;
					margin: 0;
					color: #30373D !important;
					font-weight: bolder !important;
					background-color: transparent;
				}
				.el-pagination__jump{
					margin-left: 20px;
					color: #86909C;
					font-size: 14px;
					line-height: 11px;
					display: flex;
					align-items: center;
					height: auto;
					.el-pagination__editor{
						margin: 0 8px;
						padding: 0;
						height: auto;
						width: auto;
						.el-input__inner{
							height:auto;
							min-width: auto;
							border: none;
							color: #30373D;
							padding: 3px 2px 2px;
							line-height: 14px;
							font-size: 14px;
						}
					}
				}
				.el-pagination__total{
					margin-left: 10px;
					color: #86909C;
					font-size: 14px;
					line-height: 21px;
					height: 21px;
				}
				.el-pager{
					display: flex;
					align-items: center;
					.btn-quicknext,.btn-quickprev{
						height: 21px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: transparent;
					}
					.number{
						padding: 0;
						color: #30373D;
						line-height: 11px;
						height: auto;
						min-width: auto;
						padding: 5px 6px;
						font-size: 14px;
						margin: 0;
						font-weight: 500;
						margin-right: 10px;
						background-color: transparent;
						&:last-child{
							margin-right: 0;
						}
						&:not(.disabled).active{
							color: #30373D;
							font-weight: bolder;
							background-color: #fff;
							border-radius: 1px;
						}
					}
				}
			}
		}
	}
</style>
<style lang="scss" scoped>

.empty_tender{
	text-align: center;
}
.tabs{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 50px;
	margin-bottom: 30px;
	.item{
		color: #30373D;
		font-size: 19px;
		line-height: 23px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-right: 58px;
		cursor: pointer;
		&:last-child{
			margin-right: 0;
		}
		&.active{
			color: #FF3300;
			.line{
				background-color: #FF3300;
			}
		}
		.line{
			margin-top: 8px;
			width: 34px;
			height: 3px;
			background-color: transparent;
			border-radius: 5px;
		}
	}
}
.goods_list{
	&.demand{
		.goods_item{
			cursor: pointer;
			margin-bottom: 30px;
			display: flex;
			border-radius: 10px;
			background-color: #fff;
			align-items: center;
			padding: 20px 22px;
			height: 197px;
			box-sizing: border-box;
			box-shadow: 0px 25px 56px 0px rgba(240,237,246,0.55);
			.goods_info{
				flex: 1;
				display: flex;
				align-items: center;
				.left{
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 100%;
					flex: 1;
					padding-right: 20px;
					.goods_tit{
						color: #30373D;
						font-size: 19px;
						line-height: 39px;
						font-weight: 600;
						margin-bottom: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
					.goods_tag{
						display: flex;
						align-items: center;
						height: 22px;
						overflow: hidden;
						flex-wrap: wrap;
						.tag{
							color: #30373D;
							font-size: 15px;
							line-height: 15px;
							padding: 3px 9px 4px;
							background-color: rgba(48, 55, 61, 0.05);
							border-radius: 3px;
							margin-right: 10px;
							&:last-child{
								margin-right: 0;
							}
						}
					}
					.accredits{
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						height: 64px;
						overflow: hidden;
						align-content: flex-start;
						.item{
							color: #30373D;
							font-size: 15px;
							line-height: 20px;
							padding: 0 4px;
							border: 1px solid rgba(48, 55, 61, 0.20);
							border-radius: 3px;
							margin-right: 10px;
							margin-top: 10px;
							box-sizing: border-box;
						}
					}
				}
				.right{
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: flex-end;
					.price{
						display: flex;
						align-items: baseline;
						color: #FF3300;
						font-size: 13px;
						line-height: 39px;
						.num{
							font-size: 19px;
							font-weight: 600;
						}
					}
					.row{
						display: flex;
						align-items: flex-end;
						&.first{
							margin-bottom: 10px;
							margin-top: 27px;
						}
						.info{
							display: flex;
							align-items: center;
							.purchase{
								margin-right: 0;
								font-size: 15px !important;
								line-height: 15px !important;
							}
							img{
								width: 18px;
								height: 18px;
								display: block;
								margin-right: 20px;
							}
							.iconfont1{
								font-size: 18px;
								line-height: 1;
								margin-right: 20px;
								color: #999;
								&.active{
									color: #FF3300;
								}
							}
							.logo{
								object-fit:cover;
								border-radius: 50%;
							}
							.last{
								margin-right: 0;
							}
						}
						.time{
							color: #999;
							font-size: 13px;
							line-height: 18px;
						}
						.kefu{
							color: #fff;
							font-size: 13px;
							line-height: 18px;
							padding: 7px 40px;
							border-radius: 60px;
							background-color: #30373D;
							margin-left: 52px;
							position: relative;
							&.wx{
								color: #1BCF42;
								background-color: rgba(27, 207, 66, 0.20);
								&:hover{
									img{
										display: block;
									}
								}
							}
							img{
								position: absolute;
								top: 100%;
								left: 50%;
								transform: translateX(-50%);
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
</style>