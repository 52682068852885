<template>
	<div>
		<div class="shop_info" v-loading="loading">
		    <div class="title">合作详情</div>
		    <div class="content">
				<template v-if="shop_info && shop_member_detail">
					<div class="content-item">
					    <div class="content-label">公司</div>
					    <div class="content-list">{{ shop_info.site_name }}</div>
					</div>
					<div class="content-item">
					    <div class="content-label">期限</div>
					    <div class="content-list">{{ $util.timeStampTurnTime(shop_member_detail.start_time).split(' ')[0].replaceAll('-','/') }} 至 {{ $util.timeStampTurnTime(shop_member_detail.end_time).split(' ')[0].replaceAll('-','/') }}</div>
					</div>
					<div class="content-item">
					    <div class="content-label">续期</div>
					    <div class="right_action">
							<div class="btns">
								<div :class="shop_member_detail.auto_renew==1 ? 'active':''" @click="changeAutoRener(1)">开启</div>
								<div :class="shop_member_detail.auto_renew==0 ? 'active':''" @click="changeAutoRener(0)">关闭</div>
							</div>
							<div class="cancel_btn" v-if="shop_member_detail.cancel_status_info.const=='NOT'" @click="openCancelReasonDialog()">解绑</div>
							<div class="cancel_btn" v-if="shop_member_detail.cancel_status_info.const=='WAIT_AUDIT'" >审核中</div>
							<div class="cancel_btn" v-if="shop_member_detail.cancel_status_info.const=='AUDIT_REFUSE'" @click="openCancelReasonDialog()">重新解绑</div>
							<div class="cancel_btn" v-if="shop_member_detail.cancel_status_info.const=='WAIT_AUDIT' || shop_member_detail.cancel_status_info.const=='AUDIT_REFUSE'" @click="cancelRequest()">取消解绑</div>
						</div>
					</div>
					<!-- <div class="content-item">
					    <div class="content-label">解绑</div>
					    <div class="renewal" v-if="shop_member_detail">
							<template v-if="shop_member_detail.cancel_status_info.const=='NOT'">
								<div class="btns">
									<span @click="openCancelReasonDialog()">申请</span>
								</div>
							</template>
							<template v-if="shop_member_detail.cancel_status_info.const=='WAIT_AUDIT'">
								<div class="status_desc">审核中，请耐心等待分公司审核</div>
								<div class="btns">
									<span @click="cancelRequest()">取消申请</span>
								</div>
							</template>
							<template v-if="shop_member_detail.cancel_status_info.const=='AUDIT_REFUSE'">
								<div class="status_desc">分公司已拒绝你的解绑申请，原因：{{shop_member_detail.cancel_audit_remark}}</div>
								<div class="btns">
									<span @click="openCancelReasonDialog()">重新申请</span>
									<span @click="cancelRequest()">取消申请</span>
								</div>
							</template>
					    </div>
					</div> -->
					<div class="content-item">
					    <p class="content-label">收入</p>
					    <div class="content-list">
							<div class="item">
								<div class="left">资源分成</div>
								<div class="percent">{{commission_config_info.resource_agent_commission}}%</div>
							</div>
							<div class="item">
								<div class="left">客源分成</div>
								<div class="percent">{{commission_config_info.customer_agent_commission}}%</div>
							</div>
							<div class="item">
								<div class="left">推荐分成</div>
								<div class="percent">{{commission_config_info.recommend_agent_commission}}%</div>
							</div>
							<div class="item">
								<div class="left">流程分成</div>
								<div class="percent">{{commission_config_info.progress_agent_commission}}%</div>
							</div>
							<!-- <div class="item">
								<div class="left">服务费率</div>
								<div class="percent">{{calcServiceRate()}}%</div>
							</div> -->
					    </div>
					</div>
					<div class="content-item">
					    <div class="content-label">
					        <p>联系</p>
					        <p>电话</p>
					    </div>
					    <div class="content-list contact-number">
					        <p>{{shop_info.name}}</p>
					        <p>{{shop_info.mobile}}</p>
					    </div>
					</div>
				</template>
				<template v-else>
					<div class="content-item">
					    <div class="content-label">公司</div>
					    <div class="content-list">暂无合作公司</div>
					</div>
				</template>
			</div>
		</div>
		<el-dialog class="cancel_reason" width="650px" :visible.sync="cancel_reason_dialog" :show-close="false">
			<div class="cancel_area">
				<textarea name="" placeholder="解绑原因" v-model="cancel_apply_reason"></textarea>
				<el-button type="primary" class="action_btn" @click="applyUnbinding">确认</el-button>
			</div>
			<!-- <div class="cancel_item">
				<textarea name="" placeholder="请输入解绑原因" v-model="cancel_apply_reason"></textarea>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" class="action_btn" @click="applyUnbinding">确认</el-button>
			</div> -->
		</el-dialog>
	</div>
</template>

<script>
	import { authInfo, addAuthApply, editAuthApply, shopMemberDetail, modifyAutoRenew, cancelApply, cancelApplyRevoke } from '@/api/resource';
	export default {
		data() {
			return {
				shop_info:{},
				commission_config_info:'',//佣金收入明细
				shop_member_detail:'',
				cancel_reason_dialog:false,
				cancel_apply_reason:'',
				loading:true
			}
		},
		created() {
			this.getShopMemberDetail();
		},
		methods: {
			cancelRequest(){
				// this.$confirm("您确定要取消解绑申请吗？", "提示", {
				//     confirmButtonText: "确定",
				//     cancelButtonText: "取消",
				//     type: "warning"
				// }).then(() => {
				    cancelApplyRevoke({
				        site_id:this.shop_info.site_id,
				    }).then(res => {
						if(res.code>=0){
							this.$message({
							    message: "成功取消解绑申请",
							    type: "success"
							})
							this.getShopMemberDetail();
						}else{
							this.$message.error(res.message);
						}
				    })
					.catch(err=>{
						this.$message.error(err.message);
					})
				// })
			},
			calcServiceRate(){
				return ( 100 - this.commission_config_info.customer_agent_commission - this.commission_config_info.customer_agent_site_commission - this.commission_config_info.progress_agent_commission - this.commission_config_info.recommend_agent_commission - this.commission_config_info.resource_agent_commission )
			},
			getShopMemberDetail(){
				shopMemberDetail()
				.then(res=>{
					if(res.code>=0){
						if(!res.data.shop_member_info){
							this.shop_member_detail = null;
							this.loading = false;
						}else{
							this.shop_member_detail = res.data.shop_member_info;
							this.commission_config_info = res.data.commission_config_info;
							this.shop_info = res.data.shop_info;
							this.loading = false;
							// this.shop_member_detail.cancel_status_info.const = 'NOT'
							// this.shop_member_detail.cancel_status_info.const = 'WAIT_AUDIT'
							// this.shop_member_detail.cancel_status_info.const = 'AUDIT_REFUSE'
						}
					}else{
						this.$message.error(res.message)
						this.loading = false;
					}
				})
			},
			openCancelReasonDialog(){
				this.cancel_apply_reason = '';
				this.cancel_reason_dialog = true;
			},
			applyUnbinding(){
				if(!this.cancel_apply_reason){
					this.$message.error('请填写解绑原因')
					return;
				}
				cancelApply({
					site_id:this.shop_info.site_id,
					cancel_apply_reason:this.cancel_apply_reason
				})
				.then(res=>{
					if(res.code>=0){
						this.$message({message:'申请成功'})
						this.cancel_reason_dialog = false;
						this.getShopMemberDetail();
					}else{
						this.$message.error(res.message)
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			},
			changeAutoRener(mode){
				modifyAutoRenew({
					site_id:this.shop_info.site_id,
					auto_renew:mode,
				})
				.then(res=>{
					if(res.code>=0){
						if(mode==1){
							this.$message({message:'续期成功'})
						}else{
							this.$message({message:'已关闭续期'})
						}
						this.getShopMemberDetail();
					}else{
						this.$message.error(res.message)
					}
				})
			},
		},
	}
</script>

<style lang="scss">
	.cancel_area{
		background-color: #F7F8FB;
		height: 110px;
		position: relative;
		.action_btn{
			position: absolute;
			bottom: 10px;
			right: 10px;
			color: #fff;
			background-color: #93979c;
			padding: 0;
			font-size: 15px;
			line-height: 15px;
			padding: 7px 42px;
			border: none;
			font-family: "PingFang SC";
		}
		textarea{
			width: 100%;
			padding: 10px;
			height: 80px;
			min-height: 80px;
			border: none;
			outline: none;
			box-sizing: border-box;
			background-color: #F7F8FB;
			font-size: 13px;
			line-height: 15px;
			border-radius: 10px;
			color: #30373D;
			font-family: "PingFang SC";
			resize: none;
			&::placeholder{
				font-family: "PingFang SC";
			}
		}
	}
	.cancel_reason{
		.el-dialog{
			margin-top: 25vh !important;
			border-radius: 5px;
		}
		.el-dialog__header{
			padding: 0;
		}
		.el-dialog__footer{
			padding-top: 0;
		}
		.el-dialog__body{
			padding: 14px;
		}
		.dialog-footer{
			padding-top: 4px;
			.action_btn{
				color: #30373D;
				background-color: #F7F8FB;
				border-color: #F7F8FB;
				border-radius: 30px;
				padding: 8px 53px;
				line-height: 19px;
				font-size: 14px;
				font-weight: 600;
				font-family: "PingFang SC";
			}
		}
		.cancel_item{
			display: flex;
			align-items: baseline;
			
			textarea{
				border: none;
				background-color: #F7F8FB;
				padding: 11px;
				flex: 1;
				outline: none;
				color: #30373D;
				height: 100px;
				border-radius: 8px;
				font-family: "PingFang SC";
				&::placeholder{
					color: rgba(48, 55, 61, 0.50);
					font-family: "PingFang SC";
				}
			}
		}
	}
	.shop_info {
		width: 100%;
		background-color: #FFFFFF;
	    border-radius: 10px;
	    padding: 51px 0;
	    .title{
	        text-align: center;
	        font-size: 18px;
	        font-weight: bold;
	        color: #30373D;
	        line-height: 14px;
	    }
	    .content{
			padding: 0px 344px 0 259px;;
			display: flex;
			flex-direction: column;
	        margin-top: 69px;
	        .content-item{
	            display: flex;
	            align-items: baseline;
	            margin-bottom: 25px;
	            .content-label{
	                width: 45px;
	                font-size: 15px;
	                color: #30373D;
	                line-height: 21px;
	                margin-right: 52px;
	                >p:first-child{
	                    margin-bottom: 30px;
	                }
	            }
				.right_action{
					flex:1;
					display: flex;
					align-items: center;
					justify-content: center;
					.cancel_btn{
						color: #30373D;
						background-color: #F7F8FB;
						border-radius: 5px;
						font-size: 13px;
						line-height: 18px;
						padding: 7px 21px;
						cursor: pointer;
					}
					.btns{
						display: flex;
						align-items: center;
						margin-right: 20px;
						div{
							font-size: 13px;
							line-height: 18px;
							padding: 7px 22px;
							background-color: #F7F8FB;
							border-radius: 30px;
							cursor: pointer;
							color: #30373D;
							&:last-child{
								margin-left: 20px;
							}
							&.active{
								color: #fff;
								background-color: #30373D;
							}
						}
					}
				}
	            .content-list{
	                flex: 1;
	                text-align: center;
	                font-size: 15px;
	                color: #30373D;
	                line-height: 21px;
					.item{
						color: #30373D;
						font-size: 15px;
						line-height: 18px;
						display: flex;
						align-items: center;
						margin-bottom: 20px;
						justify-content: center;
						&:last-child{
							margin-bottom: 0;
						}
					}
	                >p{
	                    margin-bottom: 20px;
	                }
	                >p:last-child{
	                    margin-bottom: 10px;
	                }
	            }
	            .contact-number{
	                >p:first-child{
	                    margin-bottom: 30px;
	                }
	                >p:last-child{
	                    margin-bottom: 0;
	                }
	            }
	            .renewal{
	                flex: 1;
	                display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;
	                font-size: 13px;
	                line-height: 15px;
					.status_desc{
						margin-bottom: 10px;
					}
					.btns{
						display: flex;
						justify-content: center;
						align-items: center;
						span{
							padding: 7px 22px;
							background-color: #F7F8FB;
							border-radius: 5px;
							cursor: pointer;
							margin-right: 20px;
							&:last-child{
								margin-right: 0;
							}
							&.active{
								color: #fff;
								background-color: #30373D;
							}
						}
					}
	            }
	        }
	    }
	}
</style>