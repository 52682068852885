<template>
    <div class="box order_list">
        <div class="list">
            <span v-for="(item, index) in statusList" :key="index" :class="{ 'active': orderStatus == item.id }"
                @click="orderStateFn(item.id)">{{ item.name }}</span>
        </div>
        <div v-loading="loading">
            <div v-if="orderList.length">
                <div class="order" v-for="(item, index) in orderList" :key="index" @click="toOrderDetail(item.order_id)">
					<img class="goods_img" :src="$img(item.order_goods[0].sku_image)" @error="item.order_goods[0].sku_image = defaultGoodsImage" @click.stop="toResourceDetail(item.order_goods[0].goods_id)" />
					<div class="right">
						<div class="order-top">
						    <div>
						        <span>订单：{{ item.order_no }} </span>
						        <span class="order-time">{{ $util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/') }}</span>
						    </div>
						    <div class="order-head">
						        <span class="time" v-if="item.order_status_info && item.order_status_info.const=='WAIT_PAY'">还剩{{calcRestDay(item.auto_close_time)}}天</span>
						        <span class="order_status">{{item.order_status_name}}</span>
						        <div class="order-money">
						            <span>￥</span>
						            <span>{{ Number(item.order_money) }}</span>
						        </div>
						    </div>
						</div>
						<div class="order-bottom">
						    <div class="goods_info">
						        <div class="title" >{{ item.order_goods[0].goods_name }}</div>
						        <div class="store">
									<span v-if="item.seller_agent_member_id != 0">官验</span>
									<span v-if="item.order_goods[0].category_name">{{item.order_goods[0].category_name}}</span>
						            <span v-for="(aItem,aIndex) in item.order_goods[0].goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</span>
						        </div>
						        <div class="price">
						            <span v-for="(priceItem, priceIndex) in item.order_goods[0].fee_data" :key="priceIndex">{{ priceItem.title+Number(priceItem.pay_price) }}</span>
						            <span>{{ '总费用'+ Number(item.goods_money) }}</span>
						        </div>
								<template v-if="role=='buyer'">
									<div class="info" v-if="item.customer_agent_info">
										<img class="headimg" :src="$img(item.customer_agent_info.headimg ? item.customer_agent_info.headimg : defaultHeadImage)" @error="item.customer_agent_info.headimg = defaultHeadImage" alt="">
									    <span>{{ item.customer_agent_info.realname ? item.customer_agent_info.realname : item.customer_agent_info.nickname }}</span>
										<div class="phone">
											<img v-if="item.customer_agent_info.mobile" src="@/assets/images/order/phone.png" alt="" class="phone" />
											<div class="seller_phone">{{item.customer_agent_info.mobile}}</div>
										</div>
									</div>
									<div class="info" v-else-if="item.seller_info">
										<img class="headimg" :src="$img(item.seller_info.headimg ? item.seller_info.headimg : defaultHeadImage)" @error="item.seller_info.headimg = defaultHeadImage" alt="">
									    <span>{{ item.seller_info.realname ? item.seller_info.realname : item.seller_info.nickname }}</span>
										<div class="phone">
											<img v-if="item.seller_info.mobile" src="@/assets/images/order/phone.png" alt="" class="phone" />
											<div class="seller_phone">{{item.seller_info.mobile}}</div>
										</div>
									</div>
								</template>
						        <template v-else>
									<div class="info" v-if="item.buyer_info">
										<img class="headimg" :src="$img(item.buyer_info.headimg ? item.buyer_info.headimg : defaultHeadImage)" @error="item.buyer_info.headimg = defaultHeadImage" alt="">
									    <span>{{ item.buyer_info.realname ? item.buyer_info.realname : item.buyer_info.nickname }}</span>
										<div class="phone">
											<img v-if="item.buyer_info.mobile" src="@/assets/images/order/phone.png" alt="" class="phone" />
											<div class="seller_phone">{{item.buyer_info.mobile}}</div>
										</div>
									</div>
								</template>
								
						    </div>
						    <div class="order-right">
						        <div v-for="(aItem,aIndex) in item.order_status_action.main" :key="aItem.title" @click.stop="operateOrder(aItem.action,item)">{{aItem.title}}</div>
								<div v-for="(aItem,aIndex) in item.refund_status_action" :key="aItem.title" @click.stop="operateOrder(aItem.action,item)">{{aItem.title}}</div>
						    </div>
						</div>
					</div>
				</div>
            </div>
            <!-- <div v-else="!orderList.length" class="empty-wrap">暂无相关订单</div> -->
        </div>
		<el-dialog class="pay_qrcode" :show-close="false" :visible.sync="qrcodeShow" :close-on-click-modal="false">
			<div class="tit">{{qrcode_type=='alipay'?'支付宝':'微信'}}<div class="line"></div></div>
			<img class="qrcode" :src="qrcode_img" />
			<div class="tips">打开{{qrcode_type=='alipay'?'支付宝':'微信'}}支付</div>
			<i class="el-icon-error" @click="closePayQrcode()"></i>
		</el-dialog>
		<div class="pager">
			<el-pagination 
				background 
				:pager-count="5" 
				:total="total" 
				layout="prev,pager,next,jumper,total"
				:current-page.sync="currentPage" 
				:page-size.sync="pageSize" 
				@size-change="handlePageSizeChange" 
				@current-change="handleCurrentPageChange" 
				hide-on-single-page>
			</el-pagination>
		</div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { orderList, orderStatus, base64Qrcode, getPayStatus } from '@/api/resource.js'
import orderMethod from '@/views/cms/order_method';
export default {
    data:()=>{
        return {
            statusList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
            loading: false,
            orderStatus: "all",
            orderList: [],
			orderData:{},
			timestamp:'',
			qrcode_img:'',
			qrcode_type:'',
			qrcodeShow:false,
			timer:'',
        }
    },
	props: {
		role: {
			type: String,
			default: '',
		},
	},
	mixins: [orderMethod],
	computed: {
	    ...mapGetters(["token", "defaultHeadImage", "defaultGoodsImage","member"])
	},
	watch: {
		role(newValue, oldValue) {
			this.loading = true;
			this.currentPage = 1;
			this.getOrderList();
		}
	},
	created() {
		this.getOrderStatus();
		this.getOrderList();
	},
    methods: {
		getOrderPayStatus(out_trade_no){
			getPayStatus({
				out_trade_no:out_trade_no
			})
			.then(res=>{
				if(res.code>=0){
					if(res.data.pay_status){
						this.$message.success('支付成功')
						this.qrcodeShow = false;
						this.qrcode_type = '';
						this.refresh();
						clearTimeout(this.timer);
					}else{
						this.timer = setTimeout(()=>{
							this.getOrderPayStatus(out_trade_no);
						},1000)
					}
				}else{
					this.$message.error(res.message);
				}
			})
			.catch(err=>{
				this.$message.error(err.message);
			})
		},
		closePayQrcode(){
			this.qrcodeShow = false;
		},
		calcRestDay(time){
			return Math.ceil((time-this.timestamp)/86400); 
		},
		toOrderDetail(id) {
			this.$router.pushToTab({path:'/member/order_detail',query:{order_id:id,role:this.role}});
		},
		toResourceDetail(id){
			this.$router.push('/resource-'+id)
		},
		operateOrder(action,orderData){
			switch (action){
				case 'orderClose':
					this.closeOrder(orderData.order_id,()=>{
						this.refresh();
					})
					break;
				case 'orderPay':
					this.payOrder(orderData.order_id,()=>{
						this.refresh();
					})
					break;
				case 'orderWechatPay':
					this.wechatPayOrder(orderData.order_id,(res)=>{
						// this.getOrderDetail();
						if(res.data){
							if(res.data.type=='url'){
								
							}else{
								//需要弹出二维码
								this.qrcode_type = 'wechat';
								this.qrcode_img = res.data.qrcode;
								this.qrcodeShow = true;
							}
							this.getOrderPayStatus(res.data.out_trade_no);
						}else{
							this.refresh();
						}
					})
					break;
				case 'orderAliPay':
					this.aliPayOrder(orderData.order_id,(res)=>{
						if(res.data){
							if(res.data.type=='url'){
								base64Qrcode({
									text:res.data.data
								})
								.then(res=>{
									if(res.code>=0){
										this.qrcode_type = 'alipay';
										this.qrcode_img = res.data;
										this.qrcodeShow = true;
									}
								})
							}
							this.getOrderPayStatus(res.data.out_trade_no);
						}else{
							this.refresh();
						}
					});
					break;
				case 'serviceComplete':
					this.serviceComplete(orderData.order_id,()=>{
						this.refresh();
					})
					break;
				case 'orderFinalPay':
					this.orderFinalPay(orderData.order_id,()=>{
						this.refresh();
					})
					break;
				case 'correctComplete':
					this.correctComplete(orderData.order_id,()=>{
						this.refresh();
					})
					break;
				case 'applyRefund':
					this.applyRefund(orderData.order_id,()=>{
						this.refresh();
					})
					break;
				case 'cancelRefund':
					this.cancelRefund(orderData.order_id,()=>{
						this.refresh();
					})
					break;
				case 'agreeRefund':
					this.agreeRefund(orderData.order_id,()=>{
						this.refresh();
					})
					break;
				case 'refuseRefund':
					this.refuseRefund(orderData.order_id,()=>{
						this.refresh();
					})
					break;
				default:
					break;
			}
		},
		getOrderList(){
			orderList({
				role:this.role,
				page:this.currentPage,
				page_size:this.pageSize,
				order_status:this.orderStatus
			})
			.then(res=>{
				if(res.code>=0){
					this.total = res.data.count;
					this.orderList = this.$util.deepClone(res.data.list);
					this.orderList.forEach(item=>{
						item.order_goods.forEach(gItem=>{
							gItem.goods_attr_parse = JSON.parse(gItem.goods_attr_format);
							gItem.fee_data = JSON.parse(gItem.fee_data);
						})
					})
					this.timestamp = res.timestamp;
					this.loading = false;
				}
			})
		},
		refresh() {
			this.loading = true;
			this.getOrderList();
		},
		handlePageSizeChange(size) {
			this.pageSize = size;
			this.refresh();
		},
		handleCurrentPageChange(page) {
			this.currentPage = page;
			this.refresh();
		},
		getOrderStatus(){
			orderStatus()
			.then(res=>{
				if(res.code>=0){
					this.statusList = [{id:'all',name:'全部'}];
					this.statusList.push(...res.data);
				}
			})
		},
        orderStateFn(status) {
            this.orderStatus = status;
			this.refresh();
        }
    }
}
</script>
<style lang="scss">
	.pay_qrcode{
		.el-dialog{
			margin-top: 0 !important;
			top: 50%;
			transform: translateY(-50%);
			max-width: 554px;
			border-radius: 10px;
		}
		.el-dialog__header{
			padding:0;
		}
		.el-dialog__body{
			position: relative;
			padding: 76px 134px 86px;
			border-radius: 10px;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.tit{
				width: 100%;
				border-bottom: 1px solid rgba(48, 55, 61, 0.10);
				color: #30373D;
				font-size: 19px;
				line-height: 23px;
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-bottom: 25px;
				position: relative;
				.line{
					width: 34px;
					height: 2px;
					background-color: #30373D;
					border-radius: 6px;
					position: absolute;
					bottom: 0;
					transform: translateY(50%);
				}
			}
			.qrcode{
				width: 224px;
				border-radius: 3px;
				display: block;
				margin-top: 30px;
			}
			.tips{
				margin-top: 30px;
				color: #999;
				font-size: 13px;
				line-height: 15px;
			}
			.el-icon-error{
				color: rgba(48, 55, 61, 0.50);
				font-size: 18px;
				line-height: 18px;
				position: absolute;
				right: 20px;
				top: 20px;
				cursor: pointer;
			}
		}
	}
	.order_list .pager{
		margin-top: 30px;
		.el-pagination{
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn-prev{
				margin-right: 15px !important;
			}
			.btn-next{
				margin-left: 15px !important;
			}
			.btn-prev,.btn-next{
				min-width: 20px;
				height: 20px;
				margin: 0;
				color: #30373D !important;
				font-weight: bolder !important;
				background-color: transparent;
			}
			.el-pagination__jump{
				margin-left: 20px;
				color: #86909C;
				font-size: 14px;
				line-height: 11px;
				display: flex;
				align-items: center;
				height: auto;
				.el-pagination__editor{
					margin: 0 8px;
					padding: 0;
					height: auto;
					width: auto;
					.el-input__inner{
						height:auto;
						min-width: auto;
						border: none;
						color: #30373D;
						font-size: 14px;
						padding: 3px 2px 2px;
						line-height: 14px;
					}
				}
			}
			.el-pagination__total{
				margin-left: 10px;
				color: #86909C;
				font-size: 14px;
				line-height: 21px;
				height: 21px;
			}
			.el-pager{
				display: flex;
				align-items: center;
				.btn-quicknext,.btn-quickprev{
					height: 21px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: transparent;
				}
				.number{
					padding: 0;
					color: #30373D;
					line-height: 11px;
					height: auto;
					min-width: auto;
					padding: 5px 6px;
					font-size: 14px;
					margin: 0;
					font-weight: 500;
					margin-right: 10px;
					background-color: transparent;
					&:last-child{
						margin-right: 0;
					}
					&:not(.disabled).active{
						color: #30373D;
						font-weight: bolder;
						background-color: #fff;
						border-radius: 1px;
					}
				}
			}
		}
	}
</style>
<style lang="scss" scoped>

.mobile_info{
	display: flex;
	align-items: center;
	.tit{
		margin-right: 20px;
	}
	.btn{
		color: #30373D;
		font-size: 13px;
		line-height: 15px;
		background-color: #F7F8FB;
		padding: 7px 22px 6px 21px;
		border-radius: 5px;
		cursor: pointer;
		box-sizing: border-box;
		border: 1px solid rgba(48, 55, 61, 0.2);
		margin-left: 30px;
	}
}
.empty-wrap{
	text-align: center;
	padding: 30px 0;
}
.box {

    .list {
        display: flex;
        align-items: center;
		justify-content: center;
        font-size: 19px;
        font-weight: 500;
        color: #30373D;
        line-height: 23px;
        >span {
			cursor: pointer;
            padding-bottom: 9px;
			margin-right: 58px;
			&:last-child{
				margin-right: 0;
			}
        }
    }

    .order {
        background-color: #fff;
        border-radius: 10px;
        margin-top: 30px;
		display:flex;
		align-items: center;
		cursor: pointer;
		.goods_img{
			width: 213px;
			height: 213px;
			display: block;
			border-radius: 10px;
			object-fit: cover;
			cursor: pointer;
		}
		.right{
			flex: 1;
			height: 213px;
			box-sizing: border-box;
			padding: 5px 22px 20px 30px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			.order-top {
			    display: flex;
			    justify-content: space-between;
			    align-items: center;
			    font-size: 13px;
			    font-weight: 500;
			    color: #999999;
			    line-height: 22px;
				.order-time {
					margin-left: 30px;
				}
			    .order-head {
			        display: flex;
			        align-items: baseline;
					.order_status{
						color:#999;
					}
					.time{
						margin-right: 10px;
					}
			        .order-money {
			            display: flex;
			            align-items: center;
			            color: #FF3300;
			            margin-left: 30px;
			            line-height: 39px;
			
			            >span:last-child {
			                font-size: 19px;
			                font-weight: bold;
			            }
			        }
			    }
			}
			
			.order-bottom {
			    display: flex;
			    justify-content: space-between;
			    align-items: center;
				.goods_info{
					height: 100%;
					flex: 1;
				}
			    >div>.title {
			        font-size: 19px;
			        font-weight: bold;
			        color: #30373D;
			        line-height: 39px;
					cursor: pointer;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
			    }
			    >div>.store {
			        display: flex;
			        align-items: center;
			        margin-top: 5px;
					flex-wrap: wrap;
					height: 22px;
					overflow: hidden;
			        >span {
			            line-height: 15px;
			            font-size: 15px;
			            color: #30373D;
			            line-height: 15px;
			            padding: 3px 9px 4px;
			            border-radius: 3px;
			            background-color: #f5f5f5;
			            margin-right: 10px;
			        }
			    }
				
			    >div>.price {
			        display: flex;
			        align-items: center;
					flex-wrap: wrap;
					height: 32px;
					overflow: hidden;
					>span {
			            line-height: 20px;
			            font-size: 15px;
			            color: #30373D;
			            padding: 0px 4px;
						margin-top: 10px;
			            margin-right: 10px;
			            border: 1px solid rgba(48, 55, 61, 0.2);
			            border-radius: 3px;
			        }
			    }
			
			    >div>.info {
			        margin-top: 25px;
			        display: flex;
			        align-items: center;
					.headimg{
						width: 18px;
						height: 18px;
						display: block;
						border-radius: 50%;
						object-fit: cover;
					}
					.phone{
						position: relative;
						width: 18px;
						height: 18px;
						cursor: pointer;
						img{
							width: 100%;
							height: 100%;
							display: block;
						}
						&:hover .seller_phone{
							display: block;
						}
					}
					.seller_phone{
						display: none;
						position: absolute;
						left: 25px;
						top: 50%;
						transform: translateY(-50%);
						line-height: 20px;
						font-size: 15px;
						color: #30373D;
						background-color: #E0E2EA;
						padding: 0px 4px;
						border-radius: 3px;
					}
			        >span {
			            font-size: 15px;
			            color: #30373D;
			            line-height: 22px;
			            margin-left: 10px;
			            margin-right: 20px;
			        }
			    }
			
			    .order-right {
					height: 100%;
					white-space: nowrap;
					margin-left: 15px;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
			        >div {
			            font-size: 13px;
			            line-height: 15px;
			            color: #30373D;
			            background-color: #E0E2EA;
			            padding: 7px 27px;
			            border-radius: 16px;
			            margin-bottom: 10px;
						cursor: pointer;
						text-align: center;
			        }
					>div:last-child{
						margin-bottom: 0;
					}
			        >div:hover {
			            color: #fff;
			            background-color: #30373D;
			        }
			    }
			}
		}
        
	}
}

.active {
    position: relative;
    color: #FF3300;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 3px;
        background-color: #FF3300;
        width: 34px;
        border-radius: 2px;
        left: 50%;
        transform: translateX(-50%);
    }
}</style>