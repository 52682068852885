import { render, staticRenderFns } from "./demand_list.vue?vue&type=template&id=dc4181d8&scoped=true"
import script from "./demand_list.vue?vue&type=script&lang=js"
export * from "./demand_list.vue?vue&type=script&lang=js"
import style0 from "./demand_list.vue?vue&type=style&index=0&id=dc4181d8&prod&lang=scss"
import style1 from "./demand_list.vue?vue&type=style&index=1&id=dc4181d8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc4181d8",
  null
  
)

export default component.exports