<template>
	<div class="setting">
		<div class="tabs">
			<div class="item active">个人设置<div class="line"></div></div>
		</div>
		<div class="member_setting" v-loading="loading">
			<div class="auth_form">
				<div class="item img">
					<div class="left">头像</div>
					<div class="right_img">
						<div class="img_item" v-for="(item,index) in headImg" :key="index">
							<el-image fit="cover" :src="$img(item)" :preview-src-list="[$img(headImg[0])]" ></el-image>
							<div @click="deleteImg(index,'headImg')" class="el-icon-error"></div>
						</div>
						<el-upload v-if="headImg.length<1" ref="upload" :show-file-list="false" :action="uploadHeadImgUrl" list-type="picture-card" :on-success=" (file, fileList) => { return handleSuccess(file, fileList,'headImg'); } " :limit="1" >
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
				</div>
				<div class="item">
					<div class="left">昵称</div>
					<input type="text" class="right_input" v-model="nickname" placeholder="昵称">
				</div>
				<div class="item">
					<div class="left">手机号</div>
					<input type="text" maxlength="11" class="right_input" v-model="mobile" placeholder="手机号" @change="changeMobile">
				</div>
				<template v-if="edit_mobile">
					<!-- <div class="item">
						<div class="left">验证码</div>
						<div class="right code">
							<input type="text" maxlength="11" class="right_input" v-model="vercode" placeholder="请输入验证码" @change="changeMobile">
							<span class="right_part">
								<img :src="captcha.img" mode class="captcha" @click="getCaptcha" />
							</span>
						</div>
					</div> -->
					<div class="item">
						<div class="left">动态码</div>
						<div class="right code">
							<input type="text" maxlength="11" class="right_input" v-model="sms_code" placeholder="验证码" >
							<span class="right_part" @click="sendMobileCode()">{{ dynacodeData.codeText }}</span>
						</div>
					</div>
				</template>
				<div class="item">
					<div class="left">联系手机</div>
					<input type="text" maxlength="11" class="right_input" v-model="contact_mobile" placeholder="联系手机号">
				</div>
				<div class="item img">
					<div class="left">联系微信</div>
					<div class="right_img">
						<div class="img_item" v-for="(item,index) in wechatImg" :key="index">
							<el-image fit="cover" :src="$img(item)" :preview-src-list="[$img(wechatImg[0])]" ></el-image>
							<div @click="deleteImg(index,'wechatImg')" class="el-icon-error"></div>
						</div>
						<el-upload v-if="wechatImg.length<1" ref="upload" :show-file-list="false" :action="uploadWechatUrl" :data="{business_type:'real_name_auth'}" list-type="picture-card" :on-success=" (file, fileList) => { return handleSuccess(file, fileList,'wechatImg'); } " :limit="1" >
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
				</div>
				<div class="item">
					<div class="left">联系QQ</div>
					<input type="text" class="right_input" v-model="qq" placeholder="联系QQ">
				</div>
				
			</div>
			<div class="apply_btn">
				<div @click="editMemberInfo()">修改</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { memberDetail, bindmobliecode, modifyMultiInfo } from '@/api/resource';
	import { captcha } from "@/api/website"
	import Config from '@/utils/config';
	export default {
		data() {
			return {
				original_mobile:'',
				nickname:'',
				mobile:'',
				headImg: [],
				contact_mobile:'',
				qq:'',
				wechatImg:[],
				uploadHeadImgUrl: Config.baseUrl + '/api/upload/headimg', //表情图标
				uploadWechatUrl: Config.baseUrl + 'mling/api/upload/commonImg', //表情图标
				loading:true,
				edit_mobile:false,
				vercode:'',//图片验证码
				captcha: {
					id: "",
					img: ""
				}, // 验证码
				sms_key:'',
				sms_code:'',
				dynacodeData: {
					seconds: 30,
					timer: null,
					codeText: "获取动态码",
					isSend: false
				}, // 动态码
				flag:false,
			}
		},
		created() {
			this.getMemberInfo();
			this.getCaptcha();
		},
		watch: {
			"dynacodeData.seconds": {
				handler(newValue, oldValue) {
					if (newValue == 0) {
						clearInterval(this.dynacodeData.timer)
						this.dynacodeData = {
							seconds: 30,
							timer: null,
							codeText: "获取动态码",
							isSend: false
						}
					}
				},
				immediate: true,
				deep: true
			}
		},
		methods: {
			sendMobileCode(formName) {
				if (this.dynacodeData.seconds != 30) return
				if(!this.mobile){
					this.$message.error('请填写手机号')
					return;
				}
				// if(!this.vercode){
				// 	this.$message.error('请填写验证码')
				// 	return;
				// }
				if(this.flag) return;
				this.flag = true;
				bindmobliecode({
					mobile: this.mobile,
					captcha_id: this.captcha.id,
					captcha_code: this.vercode
				})
				.then(res => {
					if (res.code >= 0) {
						this.sms_key = res.data.key;
						this.flag = false;
						if (this.dynacodeData.seconds == 30 && this.dynacodeData.timer == null) {
							this.dynacodeData.timer = setInterval(() => {
								this.dynacodeData.seconds--
								this.dynacodeData.codeText = this.dynacodeData.seconds + "s后可重新获取"
							}, 1000)
						}
					}else{
						this.flag = false;
					}
				})
				.catch(err => {
					this.$message.error(err.message)
					this.flag = false;
				})
			},
			changeMobile(){
				this.edit_mobile = false;
				var phonereg = /^1[3-9]\d{9}$/;
				if(!this.mobile){
					this.$message.error('请填写手机号')
					return;
				}
				if(!phonereg.test(this.mobile)){
					this.$message.error('手机号格式不正确')
					return;
				}
				if(this.mobile == this.original_mobile) return;
				this.edit_mobile = true;
			},
			editMemberInfo(){
				var phonereg = /^1[3-9]\d{9}$/;
				if(!this.nickname){
					this.$message.error('请填写昵称')
					return;
				}
				if(!this.mobile){
					this.$message.error('请填写手机号')
					return;
				}
				if(!this.contact_mobile){
					this.$message.error('请填写联系手机号')
					return;
				}
				if(!this.qq){
					this.$message.error('请填写qq')
					return;
				}
				if(!this.headImg.length){
					this.$message.error('请上传头像')
					return;
				}
				if(!this.wechatImg.length){
					this.$message.error('请上传联系微信')
					return;
				}
				if(!phonereg.test(this.mobile)){
					this.$message.error('手机号格式不正确')
					return;
				}
				if(!phonereg.test(this.contact_mobile)){
					this.$message.error('联系手机号格式不正确')
					return;
				}
				if(this.edit_mobile){
					if( !this.sms_code || !this.sms_key ){
						this.$message.error('请填写短信验证码')
						return;
					}
				}
				modifyMultiInfo({
					mobile:this.mobile,
					nickname:this.nickname,
					headimg:this.headImg[0],
					contact_mobile:this.contact_mobile,
					wechat_personal_qrcode:this.wechatImg[0],
					qq:this.qq,
					sms_key:this.sms_key,
					sms_code:this.sms_code
				})
				.then(res=>{
					if(res.code>=0){
						this.$message.success('修改成功')
						this.getMemberInfo();
						// this.$emit('');
						this.$store.dispatch('member/member_detail', { refresh: 1 });
					}else{
						this.$message.error(res.message)
					}
				})
				.catch(err=>{
					this.$message.error(err.message)
				})
			},
			getCaptcha() {
				captcha({
						captcha_id: this.captcha.id
					})
					.then(res => {
						if (res.code >= 0) {
							this.captcha.id = res.data.id
							this.captcha.img = res.data.img
							this.captcha.img = this.captcha.img.replace(/\r\n/g, "")
						}
					})
					.catch(err => {
						this.$message.error(err.message)
					})
			},
			getMemberInfo(){
				memberDetail()
				.then(res=>{
					if(res.code>=0){
						this.original_mobile = res.data.mobile;
						this.nickname = res.data.nickname;
						this.mobile = res.data.mobile;
						this.qq = res.data.qq;
						this.contact_mobile = res.data.contact_mobile;
						if(res.data.headimg){
							this.headImg = [res.data.headimg];
						}
						if(res.data.wechat_personal_qrcode){
							this.wechatImg = [res.data.wechat_personal_qrcode];
						}
						this.edit_mobile = false;
						this.loading = false;
					}
				})
			},
			deleteImg(index,mode){
				if(mode=='headImg'){
					this.headImg.splice(index,1)
				}else{
					this.wechatImg.splice(index,1)
				}
			},
			handleSuccess(file, fileList,mode) {
				if(mode=='headImg'){
					this.headImg.push(file.data.pic_path);
				}else{
					this.wechatImg.push(file.data.pic_path);
				}
			},
		},
	}
</script>
<style lang="scss">
	.right_img .el-upload--picture-card{
		width: 101px;
		height: 101px;
		line-height: 101px;
	}
</style>
<style lang="scss" scoped>
	.setting{
		.tabs{
			margin-bottom: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			.item{
				color: #30373D;
				font-size: 19px;
				line-height: 23px;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-right: 58px;
				cursor: pointer;
				&:last-child{
					margin-right: 0;
				}
				&.active{
					color: #FF3300;
					.line{
						background-color: #FF3300;
					}
				}
				.line{
					margin-top: 8px;
					width: 34px;
					height: 3px;
					background-color: transparent;
					border-radius: 5px;
				}
			}
		}
	}
	.member_setting{
		background-color: #fff;
		border-radius: 10px;
		
		.apply_btn{
			border-top: 1px solid rgba(48, 55, 61, 0.20);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 30px 0;
			div{
				padding: 6px 43px;
				border-radius: 5px;
				background-color: #F7F8FB;
				color: rgba(48, 55, 61, 0.50);
				cursor: pointer;
				font-size: 15px;
				line-height: 21px;
				&:hover{
					color: #fff;
					background-color: #30373D;
				}
			}
		}
		.auth_form{
			padding: 30px 156px;
			.item{
				display: flex;
				align-items: center;
				margin-bottom: 20px;
				&.img{
					align-items: flex-start;
					.left{
						padding-top: 10px;
					}
				}
				&:last-child{
					margin-bottom: 0;
				}
				.right{
					flex: 1;
					color: #30373D;
					font-size: 15px;
					line-height: 21px;
					padding: 10px 0;
					text-align: center;
					background-color: #F7F8FB;
					border-radius: 5px;
					&.code{
						padding: 0;
						position: relative;
						.right_part{
							top: 50%;
							left: 70%;
							transform: translate(-50%,-50%);
							position: absolute;
							cursor: pointer;
						}
					}
				}
				.right_img{
					flex: 1;
					display: flex;
					align-items: center;
					
					.img_item{
						width: 101px;
						height: 101px;
						position: relative;
						margin-right: 20px;
						.el-image{
							cursor: pointer;
							width: 100%;
							height: 100%;
							display: block;
							border-radius: 5px;
						}
						.el-icon-error{
							cursor: pointer;
							position: absolute;
							right: 0;
							top: 0;
							z-index: 10;
							transform: translate(50%,-50%);
						}
					}
				}
				.right_input{
					flex: 1;
					outline: none;
					border: none;
					padding: 0;
					text-align: center;
					color: #30373D;
					font-size: 15px;
					line-height: 21px;
					padding: 10px;
					border-radius: 5px;
					background-color: #F7F8FB;
					&::placeholder{
						font-family: "PingFang SC";
						color: rgba(48, 55, 61, 0.50);
					}
				}
				.left{
					min-width: 72px;
					margin-right: 41px;
					color:#30373D;
					font-size: 15px;
					line-height: 18px;
				}
			}
		}
	}
</style>