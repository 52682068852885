import { render, staticRenderFns } from "./withdrawal_detail.vue?vue&type=template&id=5f121976&scoped=true"
import script from "./withdrawal_detail.vue?vue&type=script&lang=js"
export * from "./withdrawal_detail.vue?vue&type=script&lang=js"
import style0 from "./withdrawal_detail.vue?vue&type=style&index=0&id=5f121976&prod&lang=scss"
import style1 from "./withdrawal_detail.vue?vue&type=style&index=1&id=5f121976&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f121976",
  null
  
)

export default component.exports