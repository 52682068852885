<template>
	<div class="auto_info" v-loading="loading">
			<div class="auth_form">
				<div class="item">
					<div class="left">状态</div>
					<div class="right">{{auth_status == 'apply' ? '未申请':auth_status=='wait'?'审核中':auth_status=='refuse'?'已拒绝':'审核通过'}}{{auth_status=='refuse' ? '   原因：'+auth_info.audit_remark:''}}</div>
				</div>
				<div class="item">
					<div class="left">证件</div>
					<div class="right">中国大陆身份证</div>
				</div>
				<div class="item">
					<div class="left">姓名</div>
					<input type="text" :readonly="!(auth_status=='apply' || auth_status =='refuse')" class="right_input" placeholder="请输入真实姓名" v-model="realname">
				</div>
				<div class="item">
					<div class="left">性别</div>
					<div v-if="!(auth_status=='apply' || auth_status =='refuse')" class="right ">{{sex_name}}</div>
					<el-select v-model="sex" placeholder="请选择" v-else>
					    <el-option
					      v-for="item in sex_list"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					</el-select>
				</div>
				<div class="item">
					<div class="left">证件号</div>
					<input type="text" :readonly="!(auth_status=='apply' || auth_status =='refuse')" class="right_input" placeholder="请输入身份证号码" v-model="idcard_no">
				</div>
				<div class="item">
					<div class="left">有效期</div>
					<el-date-picker
						:readonly="!(auth_status=='apply' || auth_status =='refuse')"
						v-model="time_area"
						format="yyyy/MM/dd"
						value-format="yyyyMMdd"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker>
				</div>
				<div class="item img">
					<div class="left">身份证图片</div>
					<div class="right_img">
						<div class="img_item" v-for="(item,index) in img_list" :key="index">
							<el-image fit="cover" :src="$img(item)" :preview-src-list="imagesList" ></el-image>
							<div v-if="auth_status=='apply' || auth_status =='refuse'" @click="deleteImg(index)" class="el-icon-error"></div>
						</div>
						<el-upload v-if="img_list.length<2" ref="upload" :show-file-list="false" :data="{business_type:'real_name_auth'}" :action="uploadActionUrl" list-type="picture-card" :on-success=" (file, fileList) => { return handleSuccess(file, fileList); } " :on-exceed="handleExceed" :limit="2" >
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
				</div>
			</div>
			<div class="apply_btn" v-if="auth_status=='apply' || auth_status =='refuse'">
				<div @click="addApply">{{auth_status=='apply'?'提交':'修改'}}</div>
			</div>
		<el-dialog title="解绑原因" class="cancel_reason" width="25%" :visible.sync="cancel_reason_dialog">
			<div class="cancel_item">
				<div class="tit">解绑原因</div>
				<textarea name="" placeholder="请输入解绑原因" v-model="cancel_apply_reason"></textarea>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel_reason_dialog = false">取消</el-button>
				<el-button type="primary" @click="applyUnbinding">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { authInfo, addAuthApply, editAuthApply, shopMemberDetail, modifyAutoRenew, cancelApply, cancelApplyRevoke } from '@/api/resource';
	import Config from '@/utils/config';
	export default {
		data() {
			return {
				loading:true,
				auth_info:'',
				tips:true,
				auth_status:'',
				idcard_no:'',
				realname:'',
				idcard_face_img:'',
				idcard_back_img:'',
				uploadActionUrl: Config.baseUrl + 'mling/api/upload/commonImg', //表情图标
				shop_member_detail:'',
				commission_config_info:'',//佣金收入明细
				shop_info:{},
				cancel_apply_reason:'',
				cancel_reason_dialog:false,
				sex_list:[
					{
					  value: 1,
					  label: '男'
					}, {
					  value: 2,
					  label: '女'
					},
				],
				sex:'',
				sex_name:'',
				time_area:'',
				img_list:[],
				idcard_start_date:'',
				idcard_end_date:'',
				imagesList:[],
			}
		},
		created() {
			this.getAuthInfo();
		},
		methods: {
			cancelRequest(){
				// this.$confirm("您确定要取消解绑申请吗？", "提示", {
				//     confirmButtonText: "确定",
				//     cancelButtonText: "取消",
				//     type: "warning"
				// }).then(() => {
				    cancelApplyRevoke({
				        site_id:this.shop_info.site_id,
				    }).then(res => {
						if(res.code>=0){
							this.$message({
							    message: "成功取消解绑申请",
							    type: "success"
							})
							this.getShopMemberDetail();
						}else{
							this.$message.error(res.message);
						}
				    })
					.catch(err=>{
						this.$message.error(err.message);
					})
				// })
			},
			openCancelReasonDialog(){
				this.cancel_apply_reason = '';
				this.cancel_reason_dialog = true;
			},
			applyUnbinding(){
				if(!this.cancel_apply_reason){
					this.$message.error('请填写解绑原因')
					return;
				}
				cancelApply({
					site_id:this.shop_info.site_id,
					cancel_apply_reason:this.cancel_apply_reason
				})
				.then(res=>{
					if(res.code>=0){
						this.$message({message:'申请成功'})
						this.cancel_reason_dialog = false;
						this.getShopMemberDetail();
					}else{
						this.$message.error(res.message)
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			},
			changeAutoRener(mode){
				modifyAutoRenew({
					site_id:this.shop_info.site_id,
					auto_renew:mode,
				})
				.then(res=>{
					if(res.code>=0){
						if(mode==1){
							this.$message({message:'续期成功'})
						}else{
							this.$message({message:'已关闭续期'})
						}
						this.getShopMemberDetail();
					}else{
						this.$message.error(res.message)
					}
				})
			},
			calcServiceRate(){
				return ( 100 - this.commission_config_info.customer_agent_commission - this.commission_config_info.customer_agent_site_commission - this.commission_config_info.progress_agent_commission - this.commission_config_info.recommend_agent_commission - this.commission_config_info.resource_agent_commission )
			},
			getShopMemberDetail(){
				shopMemberDetail()
				.then(res=>{
					if(res.code>=0){
						if(!res.data.shop_member_info){
							this.shop_member_detail = null;
						}else{
							this.shop_member_detail = res.data.shop_member_info;
							this.commission_config_info = res.data.commission_config_info;
							this.shop_info = res.data.shop_info;
						}
					}else{
						this.$message.error(res.message)
					}
				})
			},
			
			addApply(){
				var cardreg=/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
				if(!this.realname){
					this.$message.error('请填写真实姓名')
					return;
				}
				if(!this.sex){
					this.$message.error('请选择性别')
					return;
				}
				if(!this.idcard_no){
					this.$message.error('请填写身份证号')
					return;
				}
				if(!this.time_area){
					this.$message.error('请选择身份证有效期时间')
					return;
				}
				if(this.img_list.length<2){
					this.$message.error('身份证图片为正反两张图，请上传')
					return;
				}
				if(!cardreg.test(this.idcard_no)){
					this.$message.error('身份证号格式不正确')
					return;
				}
				this.idcard_start_date = this.time_area[0];
				this.idcard_end_date = this.time_area[1];
				this.idcard_back_img = this.img_list[0];
				this.idcard_face_img = this.img_list[1];
				if(this.auth_status=='apply'){
					addAuthApply({
						realname:this.realname,
						idcard_no:this.idcard_no,
						idcard_face_img:this.idcard_face_img,
						idcard_back_img:this.idcard_back_img,
						sex:this.sex,
						idcard_start_date:this.idcard_start_date,
						idcard_end_date:this.idcard_end_date
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({message:'申请成功'})
							this.getAuthInfo();
						}else{
							this.$message.error(res.message);
						}
					})
				}else{
					editAuthApply({
						realname:this.realname,
						idcard_no:this.idcard_no,
						idcard_face_img:this.idcard_face_img,
						idcard_back_img:this.idcard_back_img,
						sex:this.sex,
						idcard_start_date:this.idcard_start_date,
						idcard_end_date:this.idcard_end_date
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({message:'修改成功'})
							this.getAuthInfo();
						}else{
							this.$message.error(res.message);
						}
					})
				}
				
			},
			deleteImg(index){
				this.img_list.splice(index,1)
				this.imagesList = this.img_list.map(el=>{
					return this.$img(el);
				})
			},
			handleExceed(file, fileList) {
				// 图片数量大于6
				this.$message.warning('上传图片最大数量为1张');
			},
			handleSuccess(file, fileList) {
				this.img_list.push(file.data.pic_path);
				this.imagesList = this.img_list.map(el=>{
					return this.$img(el);
				})
			},
			getAuthInfo() {
				authInfo()
				.then(res=>{
					if(res.code>=0){
						this.auth_info = res.data;
						this.loading = false;
						if(this.auth_info==null){
							this.auth_status = 'apply';
						}else{
							if(this.auth_info.audit_status_info.const=='WAIT'){
								this.auth_status = 'wait';
							}else if(this.auth_info.audit_status_info.const=='PASS'){
								this.auth_status = 'pass';
								this.getShopMemberDetail();
							}else if(this.auth_info.audit_status_info.const=='REFUSE'){
								this.auth_status = 'refuse';
							}
							this.realname = this.auth_info.realname;
							this.idcard_no = this.auth_info.idcard_no;
							this.img_list=[];
							this.img_list.push(this.auth_info.idcard_back_img);
							this.img_list.push(this.auth_info.idcard_face_img);
							this.imagesList = this.img_list.map(el=>{
								return this.$img(el);
							})
							this.idcard_face_img = this.auth_info.idcard_face_img;
							this.idcard_back_img = this.auth_info.idcard_back_img;
							this.idcard_start_date = this.auth_info.idcard_start_date;
							this.idcard_end_date = this.auth_info.idcard_end_date;
							this.sex = this.auth_info.sex;
							this.sex_list.forEach(item=>{
								if(item.value == this.auth_info.sex) this.sex_name = item.label;
							});
							this.time_area = [this.auth_info.idcard_start_date,this.auth_info.idcard_end_date]
						}
					}else{
						this.$message.error(res.message)
					}
				})
			}
		},
	}
</script>
<style lang="scss">
	.el-select-dropdown{
		.el-scrollbar{
			.el-scrollbar__view{
				.el-select-dropdown__item{
					text-align: center;
				}
			}
		}
	}
	.el-image-viewer__canvas{
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 80%;
		height: 80%;
		img{
			object-fit: contain;
		}
	}
	.preview_img .el-dialog{
		width: 35%;
	}
	.right_img{
		.el-upload--picture-card{
			border: none;
			background-color: #F7F8FB;
			border-radius: 5px;
			width: 254px;
			height: 254px;
			line-height: 254px;
		}
	}
	.auth_form .el-select .el-input .el-select__caret {
	  transform: rotateZ(0deg);
	  width: 12px;
	  font-size: 12px;
	  line-height: 1;
	}
	.auth_form .el-select .el-input .el-select__caret::before {
	  content: "\e790";
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  top: 50%;
	  left: 50%;
	  color: #30373D !important;
	  transform: translate(-50%, -50%);
	}
	.auth_form{
		.el-select{
			flex: 1;
			background-color: #F7F8FB;
			border-radius: 5px;
			padding: 10px 0;
			.el-input--suffix{
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 1;
				.el-input__inner{
					background-color: transparent;
					border: none;
					padding: 0;
					height: auto;
					width: 45px;
					text-align: right;
					color: #30373D;
					font-size: 15px;
					line-height: 21px;
					&::placeholder{
						color: rgba(48, 55, 61, 0.50);
						font-family: "PingFang SC";
					}
				}
				.el-input__suffix{
					position: relative;
					right: 0;
					height: auto;
					margin-left: 10px;
					text-align: left;
					.el-input__suffix-inner{
						line-height: 12px;
						display: block;
					}
				}
			}
		}
		.el-date-editor{
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #F7F8FB;
			border: none;
			height: 41px;
			padding: 0;
			.el-range__close-icon{
				line-height: 41px;
				position: absolute;
				right: 33%;
			}
			.el-range-separator{
				width: 15px;
				padding: 0;
				height: 100%;
				line-height: 41px;
				color: #30373D;
			}
			.el-range-input{
				background-color: transparent;
				color: #30373D;
				font-size: 15px;
				line-height: 41px;
				height: 100%;
				width: 88px;
				&::placeholder{
					color: rgba(48, 55, 61, 0.50);
					font-family: "PingFang SC";
				}
			}
			.el-input__icon.el-icon-date{
				display: none;
			}
		}
	}
	
</style>
<style lang="scss" scoped>
	.cancel_reason{
		.cancel_item{
			display: flex;
			align-items: baseline;
			.tit{
				margin-right: 10px;
			}
			textarea{
				flex: 1;
				outline: none;
				height: 100px;
			}
		}
	}
	.auto_info{
		background-color: #fff;
		border-radius: 10px;
		.auth_form{
			padding: 30px 156px;
			.item{
				display: flex;
				align-items: center;
				margin-bottom: 20px;
				&.img{
					align-items: flex-start;
					.left{
						padding-top: 10px;
					}
				}
				&:last-child{
					margin-bottom: 0;
				}
				
				.right{
					flex: 1;
					color: #30373D;
					font-size: 15px;
					line-height: 21px;
					padding: 10px 0;
					text-align: center;
					background-color: #F7F8FB;
					border-radius: 5px;
				}
				.right_img{
					flex: 1;
					display: flex;
					align-items: center;
					.img_item{
						width: 254px;
						height: 254px;
						position: relative;
						margin-right: 20px;
						.el-image{
							cursor: pointer;
							width: 100%;
							height: 100%;
							display: block;
							border-radius: 5px;
						}
						.el-icon-error{
							cursor: pointer;
							position: absolute;
							right: 0;
							top: 0;
							z-index: 10;
							transform: translate(50%,-50%);
						}
					}
				}
				.right_input{
					flex: 1;
					outline: none;
					border: none;
					padding: 0;
					text-align: center;
					color: #30373D;
					font-size: 15px;
					line-height: 21px;
					padding: 10px;
					border-radius: 5px;
					background-color: #F7F8FB;
					&::placeholder{
						font-family: "PingFang SC";
						color: rgba(48, 55, 61, 0.50);
					}
				}
				.left{
					min-width: 72px;
					margin-right: 41px;
					color:#30373D;
					font-size: 15px;
					line-height: 18px;
				}
			}
		}
		.apply_btn{
			border-top: 1px solid rgba(48, 55, 61, 0.20);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 30px 0;
			div{
				padding: 6px 43px;
				border-radius: 5px;
				background-color: #F7F8FB;
				color: rgba(48, 55, 61, 0.50);
				cursor: pointer;
				font-size: 15px;
				line-height: 21px;
				&:hover{
					color: #fff;
					background-color: #30373D;
				}
			}
		}
	}
	.shop_info {
		width: 100%;
		background-color: #FFFFFF;
	    border-radius: 10px;
	    padding: 51px 0;
	    .title{
	        text-align: center;
	        font-size: 18px;
	        font-weight: bold;
	        color: #30373D;
	        line-height: 14px;
	    }
	    .content{
			padding: 0px 344px 0 259px;;
			display: flex;
			flex-direction: column;
	        margin-top: 69px;
	        .content-item{
	            display: flex;
	            align-items: baseline;
	            margin-bottom: 25px;
	            .content-label{
	                width: 30px;
	                font-size: 15px;
	                font-weight: bold;
	                color: #30373D;
	                line-height: 18px;
	                margin-right: 67px;
	                >p:first-child{
	                    margin-bottom: 30px;
	                }
	            }
	            .content-list{
	                flex: 1;
	                text-align: center;
	                font-weight: 500;
	                font-size: 15px;
	                color: #30373D;
	                line-height: 18px;
					.item{
						color: #30373D;
						font-size: 15px;
						line-height: 18px;
						display: flex;
						align-items: center;
						margin-bottom: 20px;
						justify-content: center;
						&:last-child{
							margin-bottom: 0;
						}
					}
	                >p{
	                    margin-bottom: 20px;
	                }
	                >p:last-child{
	                    margin-bottom: 10px;
	                }
	            }
	            .contact-number{
	                >p:first-child{
	                    margin-bottom: 30px;
	                }
	                >p:last-child{
	                    margin-bottom: 0;
	                }
	            }
	            .renewal{
	                flex: 1;
	                display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;
	                font-size: 13px;
	                line-height: 15px;
					.status_desc{
						margin-bottom: 10px;
					}
					.btns{
						display: flex;
						justify-content: center;
						align-items: center;
						span{
							padding: 7px 22px;
							background-color: #F7F8FB;
							border-radius: 5px;
							cursor: pointer;
							margin-right: 20px;
							&:last-child{
								margin-right: 0;
							}
							&.active{
								color: #fff;
								background-color: #30373D;
							}
						}
					}
	            }
	        }
	    }
	}
</style>