<template>
	<div class="brokerage_module">
		<div class="tabs">
			<div class="item" :class="current_state == 1 ? 'active':''" @click="chooseMode(1)">认证<div class="line"></div></div>
			<div class="item" :class="current_state == 2 ? 'active':''" @click="chooseMode(2)">合作公司<div class="line"></div></div>
		</div>
		<auto-info v-if="current_state==1"></auto-info>
		<branch-office v-if="current_state==2"></branch-office>
	</div>
</template>

<script>
	import autoInfo from '@/components/authInfo'
	import branchOffice from '@/components/branchOffice'
	export default {
		data() {
			return {
				current_state:1,
			}
		},
		components: {
			autoInfo,
			branchOffice
		},
		methods: {
			chooseMode(mode) {
				this.current_state = mode;
			}
		},
	}
</script>

<style lang="scss">
	.brokerage_module{
		.tabs{
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 30px;
			.item{
				color: #30373D;
				font-size: 19px;
				line-height: 23px;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-right: 58px;
				cursor: pointer;
				&:last-child{
					margin-right: 0;
				}
				&.active{
					color: #FF3300;
					.line{
						background-color: #FF3300;
					}
				}
				.line{
					margin-top: 8px;
					width: 34px;
					height: 3px;
					background-color: transparent;
					border-radius: 5px;
				}
			}
		}
	}
	
</style>